import { Button, Checkbox, Heading, Popover, Stack } from "@shopify/polaris";
import { CircleCancelMinor } from "@shopify/polaris-icons";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import styled from "styled-components";
import img from "../../../assets/images/img-placeholder.png";
import { API_DOMAIN } from "../../../config";
import { IFRAME_MESSAGE_TYPE, MOCKUP_TYPE } from "../../../constants";
import { useAppContext } from "../../../context";
import { useFetch, useToggle } from "../../../hooks";
import { Box } from "../../CanvasBuilder";
import { Consumer } from "../../MockupTemplate/Form";
// import { FooterAction } from "./FooterAction";
import { useDesignContext } from "../context";
import { TopAction } from "./TopAction";
import { ChooseOtherMockup } from "./ChooseOtherMockup";
import { cloneDeep } from "lodash";
import { calculateMainMockup } from "./GeneratedMockups";
import { checkRole } from "../../../utils";

const URL = `${API_DOMAIN}/template`;

export function ConfigDesign() {
    // Context
    const {
        mockupActive,
        isEdit,
        preview,
        templateActive,
        updateState,
        templateSelected,
        baseActive,
        cloneBases,
        setCloneBases,
        loading,
    } = useDesignContext() || {};
    const curTemplate = mockupActive?.template;

    const {
        bases = [],
        id: templateId,
        isMainCustomMockup,
    } = templateActive || {};
    const { user } = useAppContext();
    const { isDesigner } = checkRole(user);

    // Props
    const outputMockup = mockupActive?.outputMockup;

    const src = outputMockup?.url
        ? outputMockup?.url
        : outputMockup?.thumbnailUrl
        ? outputMockup.thumbnailUrl
        : null;

    // State
    const [templates, setTemplates] = useState([]);
    const [filter] = useState({
        limit: 1000,
        page: 1,
    });

    // Refs
    const updateTmpRef = useRef(false);
    const updateTmpTimeout = useRef(null);
    const templateSelectedRef = useRef(templateSelected);
    templateSelectedRef.current = templateSelected;

    // Query
    const [getTemplates] = useFetch({ url: URL, method: "get" });
    useEffect(() => {
        if (user != null) {
            (async function () {
                try {
                    const { data, error } = await getTemplates({
                        params: filter,
                    });
                    if (data?.nodes?.length > 0 && error == null) {
                        setTemplates(data.nodes);
                    }
                } catch (err) {}
            })();
        }
    }, [filter, getTemplates, user]);

    // Actions
    const mapMockupsByTemplateId = useCallback((tmpId, taskBaseSubmissions) => {
        const cloneSub = [...(taskBaseSubmissions || [])];
        if (!cloneSub || cloneSub.length === 0) return [];

        const mockups = [];
        for (let i = 0; i < cloneSub.length; i++) {
            const submission = cloneSub[i];
            const submissionFiles = submission.taskBaseSubmissionFiles;

            mockups.push(
                ...(submission.taskBaseMockups || []).map((sub) => {
                    return {
                        ...sub,
                        submissionFiles,
                    };
                })
            );
        }

        return mockups.filter((i) => i.originTemplateId === tmpId);
    }, []);

    const updateTemplateSelected = useCallback(
        (bases) => {
            if (
                !templateSelected ||
                templateSelected.length === 0 ||
                updateTmpRef.current
            )
                return;
            const cloneTemplates = [...templateSelected];
            const index = cloneTemplates.findIndex(
                (tmp) => tmp.id === templateId
            );
            if (index > -1) {
                cloneTemplates.splice(index, 1, {
                    ...cloneTemplates[index],
                    bases: JSON.parse(JSON.stringify(bases)),
                });
            }

            const templateActive = cloneTemplates.find(
                (i) => i.id === templateId
            );

            updateState({ templateSelected: cloneTemplates, templateActive });
            updateTmpRef.current = true;
        },
        [templateId, templateSelected, updateState]
    );

    useEffect(() => {
        if (bases?.length > 0 && templates?.length > 0) {
            // Find index base have submission.
            const indexBaseHasSub = bases.findIndex(
                (b) => b?.taskBaseSubmissions?.length > 0
            );
            let newBases = bases
                .map((base, index) => {
                    const {
                        mockupTemplates,
                        baseConfigId,
                        taskBaseSubmissions,
                    } = base || {};

                    const firstBase = index === indexBaseHasSub;

                    if (taskBaseSubmissions?.length === 0) return null;

                    // Filter template in submissions
                    const [sub] = taskBaseSubmissions;
                    const { taskBaseMockups } = sub || {};
                    const originTemplateIds = (taskBaseMockups || [])
                        .map((i) => i?.originTemplateId)
                        .filter(Boolean);

                    const templatesDefault = (mockupTemplates || [])
                        .filter((i) =>
                            originTemplateIds.includes(i?.originTemplateID)
                        )
                        .filter(Boolean);

                    const matchTemplates = (templatesDefault || []).map(
                        (m, indexTmp) => {
                            const firsTmp = firstBase && indexTmp === 0;

                            const curTmp = templates.find(
                                (i) => i.id === m.originTemplateID
                            );

                            if (curTmp != null) {
                                const { id, layers, ...rest } = curTmp;

                                const internalId = `${templateId}-${baseConfigId}-${m.originTemplateID}`;
                                const mockups = mapMockupsByTemplateId(
                                    id,
                                    taskBaseSubmissions
                                ).map((mockup, indexMockup) => {
                                    const {
                                        submissionFiles,
                                        mockupLayerConfigs,
                                    } = mockup;
                                    const { layers } = curTmp || {};
                                    const firstMockup =
                                        firsTmp && indexMockup === 0;

                                    const curLayers =
                                        mockupLayerConfigs?.layers || [];
                                    const newLayers = getLayers(
                                        layers,
                                        submissionFiles,
                                        curLayers
                                    );

                                    return {
                                        ...mockup,
                                        active: firstMockup,
                                        curLayers,
                                        template: {
                                            ...curTmp,
                                            layers: newLayers,
                                        },
                                    };
                                });

                                return {
                                    ...rest,
                                    ...m,
                                    mockups,
                                    internalId,
                                    isMainMockup: (mockups || []).some(
                                        (e) => e?.isMainMockup
                                    ),
                                    active: firsTmp,
                                };
                            }

                            return m;
                        }
                    );

                    if (firstBase) {
                        const [tmpFirst] = matchTemplates;
                        const [mockupFirst] = tmpFirst?.mockups || [];
                        const mockups = (tmpFirst?.mockups || []).map(
                            (i, index) => {
                                return {
                                    ...i,
                                    active: index === 0,
                                };
                            }
                        );
                        updateState({
                            baseActive: { ...tmpFirst, mockups },
                            mockupActive: mockupFirst,
                        });
                    }

                    return {
                        ...base,
                        matchTemplates,
                    };
                })
                .filter(Boolean);

            // Set first item have main mockup
            const haveMainMockup = (newBases || []).some((b) => {
                const { matchTemplates } = b;
                return (matchTemplates || []).some((tm) => tm.isMainMockup);
            });

            if (!haveMainMockup && !isMainCustomMockup) {
                newBases = newBases.map((b, index) => {
                    if (!index) {
                        const { matchTemplates } = b;

                        const newTemplates = (matchTemplates || []).map(
                            (tm, idx) => {
                                if (!idx) {
                                    const { mockups } = tm;
                                    const newMockups = (mockups || []).map(
                                        (m, i) => {
                                            if (!i) {
                                                return {
                                                    ...m,
                                                    isMainMockup: true,
                                                };
                                            }
                                            return m;
                                        }
                                    );

                                    return {
                                        ...tm,
                                        mockups: newMockups,
                                        isMainMockup: true,
                                    };
                                }

                                return tm;
                            }
                        );

                        return {
                            ...b,
                            matchTemplates: newTemplates,
                        };
                    }

                    return b;
                });
            }

            setCloneBases(newBases);

            updateTemplateSelected(newBases);
        }
    }, [
        templates,
        bases,
        templateId,
        mapMockupsByTemplateId,
        updateState,
        updateTemplateSelected,
        setCloneBases,
        isMainCustomMockup,
    ]);

    const handleUpdateBases = useCallback(
        (base) => {
            return (tmpIds, newTemplates) => {
                updateTmpRef.current = false;
                if (base == null || tmpIds?.length === 0) return;
                const { id: baseId } = base;

                let newBases = [];
                setCloneBases((prev) => {
                    newBases = (prev || []).map((b) => {
                        if (b.id === baseId) {
                            const newSubmission = changeSubmissions(
                                b.taskBaseSubmissions,
                                tmpIds
                            );

                            return {
                                ...b,
                                matchTemplates: newTemplates,
                                taskBaseSubmissions: newSubmission,
                            };
                        }
                        return b;
                    });

                    updateTemplateSelected(newBases);

                    updateTmpTimeout.current = clearTimeout(
                        updateTmpTimeout.current
                    );

                    updateTmpTimeout.current = setTimeout(() => {
                        return newBases;
                    }, 100);
                });
            };
        },
        [updateTemplateSelected, setCloneBases]
    );

    const handleSendMockupIds = useCallback(() => {
        if (cloneBases?.length > 0) {
            const ids = cloneBases.reduce((acc, base) => {
                const { matchTemplates } = base || {};
                const tmp = (matchTemplates || []).find((i) => i.isMainMockup);
                const mockup = (tmp?.mockups || []).find((m) => m.isMainMockup);

                return mockup != null ? [...acc, mockup.id] : acc;
            }, []);
            const data = {
                type: IFRAME_MESSAGE_TYPE.GetMainMockupId,
                input: ids.slice(ids.length - 1),
            };
            window?.parent?.postMessage(JSON.stringify(data), "*");
        }
    }, [cloneBases]);

    // Get data from `subscription` in MB.
    const handleReceiveMessage = useCallback(
        (event) => {
            const data = event?.data;
            if (data != null && typeof data === "string") {
                const value = JSON.parse(window.atob(data));

                const { type, message } = value || {};

                const isSubscriptions =
                    type === IFRAME_MESSAGE_TYPE.Subscriptions;
                const isRegenerate = type === IFRAME_MESSAGE_TYPE.Regenerate;
                const isGetMainMockupId =
                    type === IFRAME_MESSAGE_TYPE.GetMainMockupId;
                if (isGetMainMockupId) {
                    handleSendMockupIds();
                }

                const templateSelected = templateSelectedRef.current;
                if (message != null && templateSelected?.length > 0) {
                    const newMockup =
                        Array.isArray(message) && message?.length > 0
                            ? message[0]
                            : message;

                    const tmpSelected = [...templateSelected];
                    for (let i = 0; i < tmpSelected.length; i++) {
                        const newBases = updateBases(
                            templateSelected[i].bases,
                            newMockup,
                            message,
                            isSubscriptions,
                            isDesigner
                        );
                        tmpSelected[i].bases = [...newBases];
                    }

                    const newState = {
                        templateSelected: [...tmpSelected],
                        templateActive: [...tmpSelected].find(
                            (i) => i.id === templateActive?.id
                        ),
                    };
                    // Set loading for Button.
                    let newLoading = loading;
                    if (isRegenerate && message?.length > 0) {
                        newLoading = false;

                        const curTemp = tmpSelected.find(
                            (t) => t.id === templateActive.id
                        );
                        if (curTemp != null) {
                            const { internalId } = baseActive || {};
                            const [, baseId] = splitInternalId(internalId);
                            const curBase = (curTemp.bases || []).find(
                                (b) => b.id === baseId
                            );

                            const mockups = [];
                            if (
                                curBase &&
                                curBase.taskBaseSubmissions?.length > 0
                            ) {
                                curBase.taskBaseSubmissions.forEach((sub) => {
                                    if (sub?.taskBaseMockups?.length > 0) {
                                        mockups.push(...sub.taskBaseMockups);
                                    }
                                });
                            }
                            let mockupActive = mockups[0] || null;

                            newState.mockupActive = mockupActive;
                            newState.isEdit = false;
                            newState.preview = false;
                            newState.loading = newLoading;
                            newState.changedLayer = false;
                        }
                    }

                    newState.baseLoading = [];
                    updateState(newState);
                }
            }
        },
        [
            updateState,
            loading,
            templateActive,
            baseActive,
            handleSendMockupIds,
            isDesigner,
        ]
    );

    useEffect(() => {
        window.addEventListener("message", handleReceiveMessage);

        return () => {
            window.removeEventListener("message", handleReceiveMessage, false);
        };
    }, [handleReceiveMessage]);

    return (
        <Wrapper className="content">
            <div className="content-left">
                {cloneBases?.length > 0 ? (
                    <div className="bases-wrapper">
                        <Heading element="h3" children="Mockup Templates" />
                        <div className="bases-wrap">
                            {cloneBases.map((b, index) => (
                                <BaseItem
                                    key={`base-${index}`}
                                    base={b}
                                    templates={templates}
                                    updateState={updateState}
                                    baseActive={baseActive}
                                    mockupActive={mockupActive}
                                    bases={cloneBases}
                                    setCloneBases={setCloneBases}
                                    onUpdateBases={handleUpdateBases(b)}
                                />
                            ))}
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="content-right">
                <Stack vertical spacing="loose" wrap={false}>
                    <TopAction />
                    <div style={{ width: "95%", margin: "0 auto" }}>
                        {src && !isEdit && !preview ? (
                            <div style={{ width: "100%", height: "auto" }}>
                                <img
                                    src={src}
                                    width="100%"
                                    height="100%"
                                    alt=""
                                />
                            </div>
                        ) : (
                            <Consumer value={curTemplate} isDesignMockup>
                                <Box isConfigDesign />
                                {/* isConfigDesign */}
                            </Consumer>
                        )}
                    </div>
                    {/* <FooterAction /> */}
                </Stack>
            </div>
        </Wrapper>
    );
}

function BaseItem({
    base,
    updateState,
    baseActive,
    mockupActive,
    setCloneBases,
    onUpdateBases,
    bases,
}) {
    const baseId = base?.id;
    // Props
    const { title, matchTemplates, taskBaseSubmissions } = base;
    const [sub] = taskBaseSubmissions || [];
    const { id: taskBaseSubmissionId } = sub || {};

    // State
    const timeoutRef = useRef(null);
    const matchTemplatesRef = useRef(matchTemplates);
    matchTemplatesRef.current = matchTemplates;

    // Actions
    const handleDeleteMockup = useCallback(
        (originTemplateId) => {
            if (originTemplateId != null && taskBaseSubmissionId != null) {
                const data = {
                    type: IFRAME_MESSAGE_TYPE.DeleteMockups,
                    input: {
                        originTemplateId,
                        taskBaseSubmissionId,
                    },
                };

                window?.parent?.postMessage(JSON.stringify(data), "*");
            }
        },
        [taskBaseSubmissionId]
    );

    const handleRemove = useCallback(
        (index) => {
            return () => {
                const matchTemplates = matchTemplatesRef.current || [];
                let clone = [...matchTemplates];
                const templateRemoved = clone.find((_, i) => i === index);
                const newState = {};
                const { originTemplateID: originTemplateId, active } =
                    templateRemoved || {};

                // Delete mockups in MB
                handleDeleteMockup(originTemplateId);

                if (index > -1) {
                    clone.splice(index, 1);
                }

                // If remove template is active, do set active for template `index` - 1
                if (active) {
                    clone = [...clone].map((el, idx) => {
                        if (idx === Math.max(0, index - 1)) {
                            return {
                                ...el,
                                active: true,
                            };
                        }
                        return el;
                    });
                }
                onUpdateBases([originTemplateId], clone);

                // When remove template, if it is active remove `baseActive` and `mockupActive` in `context`
                if (mockupActive != null && templateRemoved != null) {
                    const baseCId = mockupActive.taskBaseConfigId;
                    const originTmpId = mockupActive.originTemplateId;
                    const internalId = templateRemoved.internalId;
                    const [, curBaseCId, curId] = splitInternalId(internalId);
                    if (
                        baseCId === curBaseCId &&
                        originTmpId === parseInt(curId) &&
                        clone.length === 0
                    ) {
                        newState.baseActive = null;
                        newState.mockupActive = null;

                        // reset `baseActive` and `mockupActive`.
                    } else if (clone.length > 0) {
                        const baseActive = clone.find((i) => i.active);
                        const { mockups } = baseActive || {};
                        const [mockupFirst] = mockups || [];
                        newState.baseActive = baseActive;
                        newState.mockupActive = mockupFirst;
                    }
                }

                updateState({ ...newState });
            };
        },
        [mockupActive, updateState, handleDeleteMockup, onUpdateBases]
    );

    const handleRemoveTemplate = useCallback(
        (templateIdsToRemoved) => {
            if (templateIdsToRemoved?.length > 0 && taskBaseSubmissionId) {
                const matchTemplates = matchTemplatesRef.current || [];
                const cloneTemplates = [...matchTemplates];

                const templateToRemoved = [];
                let templateRemain = [];
                for (let i = 0; i < cloneTemplates.length; i++) {
                    const tmp = cloneTemplates[i];
                    if (templateIdsToRemoved.includes(tmp.id)) {
                        templateToRemoved.push(tmp);
                    } else {
                        templateRemain.push(tmp);
                    }
                }

                const haveActive = templateToRemoved.some((i) => i.active);
                if (haveActive) {
                    templateRemain[0].active = true;
                }

                onUpdateBases(templateIdsToRemoved, templateRemain);

                const data = {
                    type: IFRAME_MESSAGE_TYPE.BulkDeleteMockups,
                    input: {
                        originTemplateIds: templateIdsToRemoved,
                        taskBaseSubmissionId,
                    },
                };

                window?.parent?.postMessage(JSON.stringify(data), "*");
            }
        },
        [taskBaseSubmissionId, onUpdateBases]
    );

    const handleBaseActive = useCallback(
        (value, matchTemplates) => {
            timeoutRef.current && clearTimeout(timeoutRef.current);
            timeoutRef.current = setTimeout(
                () => {
                    const clone = [...matchTemplates].map((i) => {
                        if (i.id === value.id) {
                            const newActive = value.active;
                            let newTmp = {
                                ...i,
                            };

                            const newState = {
                                baseActive: newTmp,
                                isEdit: false,
                                preview: false,
                            };

                            if (newActive && baseActive?.id === value.id) {
                            } else {
                                newTmp.active = !newActive;
                                newState.baseActive = newTmp;
                                const [mockupFirst] = newTmp.mockups || [];
                                newState.mockupActive = mockupFirst;
                                newState.baseActive.mockups =
                                    newState.baseActive.mockups.map(
                                        (b, index) => ({
                                            ...b,
                                            active: index === 0,
                                        })
                                    );
                            }
                            updateState(newState);
                            return newTmp;
                        }
                        return {
                            ...i,
                            active: false,
                        };
                    });

                    setCloneBases((prev) => {
                        return (prev || []).map((b) => {
                            if (b.id === baseId) {
                                return {
                                    ...b,
                                    matchTemplates: clone,
                                };
                            }

                            return {
                                ...b,
                                matchTemplates: resetActive(b.matchTemplates),
                            };
                        });
                    });
                },
                value?.id !== baseActive?.id ? 500 : 0
            );
        },
        [baseActive, updateState, setCloneBases, baseId]
    );

    const handleViewBase = useCallback(
        (value) => {
            return () => {
                handleBaseActive(value, matchTemplates);
            };
        },
        [matchTemplates, handleBaseActive]
    );

    function resetActive(arr) {
        if (!arr || arr.length === 0) return [];
        return arr.map((i) => ({ ...i, active: false }));
    }

    const handleChooseMockup = useCallback(
        (tmp) => {
            return (value) => {
                const { id } = tmp || {};

                setCloneBases((prev) => {
                    const cloneBases = updateMainMockup(
                        prev,
                        baseId,
                        id,
                        value
                    );
                    return cloneBases;
                });
            };
        },
        [baseId, setCloneBases]
    );

    return (
        <div className="base-wrap">
            <Stack alignment="center">
                <Stack.Item fill>
                    <span
                        title={title}
                        style={{
                            fontSize: "1.4rem",
                            fontWeight: 600,
                        }}
                    >
                        {title}
                    </span>
                </Stack.Item>
            </Stack>
            <div className="design-position-wrap">
                <div className="templates-wrap">
                    {matchTemplates?.length > 0
                        ? [...matchTemplates].map((tmp, index, arr) => (
                              <TemplateItem
                                  key={`template-${index}`}
                                  value={tmp}
                                  onRemove={handleRemove(index)}
                                  onViewBase={handleViewBase(tmp)}
                                  haveRemove={arr.length > 1}
                                  bases={bases}
                                  onChooseMockup={handleChooseMockup(tmp)}
                              />
                          ))
                        : null}
                    <ChooseOtherMockup
                        matchTemplates={matchTemplates}
                        base={base}
                        onRemoveTemplate={handleRemoveTemplate}
                    />
                </div>
            </div>
        </div>
    );
}

function TemplateItem({
    value,
    onRemove,
    onViewBase,
    haveRemove,
    bases,
    onChooseMockup,
}) {
    const { name, file, active, isMainMockup } = value || {};

    const src = useMemo(() => {
        let result = file?.thumbnail_url
            ? file?.thumbnail_url
            : file?.url
            ? file.url
            : img;

        return result;
    }, [file]);

    const activeCls = active ? "active" : "";

    // State
    const [open, toggleOpen] = useToggle(false);

    const handleRemove = useCallback(() => {
        toggleOpen(false);
        onRemove?.();
    }, [onRemove, toggleOpen]);

    // Markup
    const activator = (
        <Button icon={CircleCancelMinor} onClick={toggleOpen} plain />
    );

    return (
        <div className={`template-wrap ${activeCls}`}>
            {haveRemove && (
                <div className="btn-wrap">
                    <Popover
                        active={open}
                        onClose={toggleOpen}
                        activator={activator}
                        preferredPosition="above"
                        sectioned
                    >
                        <Stack spacing="tight" vertical>
                            <p>Are you sure to delete this mockup?</p>
                            <Button
                                children="Submit"
                                onClick={handleRemove}
                                size="slim"
                            />
                        </Stack>
                    </Popover>
                </div>
            )}
            <div className="img-wrap">
                <img
                    src={src}
                    alt=""
                    onDragStart={(e) => e.preventDefault()}
                    onClick={onViewBase}
                />
            </div>
            <span className="title-wrap">
                <Checkbox checked={isMainMockup} onChange={onChooseMockup} />
                <span title={name} className="title" onClick={onViewBase}>
                    {name}
                </span>
            </span>
        </div>
    );
}

function updateMainMockup(bases, curBaseId, curTemplateId, value) {
    if (
        !(bases instanceof Array) ||
        bases.length === 0 ||
        curBaseId == null ||
        curTemplateId == null
    )
        return [];

    const cloneBases = cloneDeep(bases).map((b) => {
        if (b.id === curBaseId) {
            const { matchTemplates } = b || {};
            const cloneTemplates = calculateMainMockup(
                matchTemplates,
                curTemplateId,
                value
            );

            return {
                ...b,
                matchTemplates: (cloneTemplates || []).map((tm) => {
                    if (tm.isMainMockup) {
                        const { mockups } = tm;
                        let newMockups = cloneDeep(mockups);
                        const haveMainMockup = (mockups || []).some(
                            (m) => m.isMainMockup
                        );

                        if (!haveMainMockup) {
                            newMockups = newMockups.map((m, index) => {
                                if (!index) {
                                    return {
                                        ...m,
                                        isMainMockup: true,
                                    };
                                }
                                return m;
                            });
                        }

                        return {
                            ...tm,
                            mockups: newMockups,
                        };
                    }

                    return {
                        ...tm,
                        mockups: resetMainMockup(tm.mockups),
                    };
                }),
            };
        }
        return {
            ...b,
            matchTemplates: (b.matchTemplates || []).map((tm) => {
                const { mockups } = tm;
                const newMockups = resetMainMockup(mockups);

                return {
                    ...tm,
                    mockups: newMockups,
                    isMainMockup: false,
                };
            }),
        };
    });

    return cloneBases;
}

export function resetMainMockup(arr, value = false) {
    if (!(arr instanceof Array) || arr.length === 0) return [];
    const cloneArr = cloneDeep(arr);

    return cloneArr.map((i) => ({ ...i, isMainMockup: value }));
}

// Match layers
function getLayers(layers, submissionFiles, curLayers) {
    if (!layers || layers.length === 0) return [];

    return layers.map((l) => {
        const { name: lName, type } = l || {};
        const matchByDP = (submissionFiles || []).find(
            (i) => i?.designPosition?.name === lName
        );

        if (matchByDP) {
            const { file, designPosition, generateMockupFile } = matchByDP;
            let url;
            if (generateMockupFile && "url" in generateMockupFile) {
                ({ url } = generateMockupFile);
            } else if (file && "url" in file) {
                ({ url } = file);
            }

            // Map layer current
            const mapCurLayer =
                (curLayers || []).find((l) => l.name === lName) || {};
            const { __typename, ...restCurLayer } = mapCurLayer;

            const objLayer = getValueTruthy(restCurLayer);
            if (url && type === MOCKUP_TYPE.Design) {
                return {
                    ...l,
                    imageUrl: url,
                    designPosition,
                    ...objLayer,
                };
            }
        }

        return l;
    });
}

function getValueTruthy(obj) {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (isNaN(value)) {
            if (value) {
                acc[key] = value;
            }
        } else if (typeof value === "number") {
            if (value > -1) {
                acc[key] = value;
            }
        }

        return acc;
    }, {});
}

/**
 * Update bases in template by value `Subscription` from MB
 * @param {*} bases Bases in template.
 * @param {*} newMockup Value from MB
 * @returns Array
 */
function updateBases(
    bases,
    newMockup = {},
    message,
    isSubscriptions,
    isDesigner
) {
    if (!bases || bases.length === 0) return [];

    const {
        taskBaseSubmissionId,
        taskBaseConfigId,
        status,
        id,
        outputMockup,
        originTemplateId,
    } = newMockup;

    return (bases || []).map((b) => {
        if (b.baseConfigId === taskBaseConfigId) {
            const ids = {
                baseSubId: taskBaseSubmissionId,
                mockupId: id,
                temId: originTemplateId,
            };
            const newValue = {
                status,
                outputMockup,
                message,
            };
            const taskBaseSubmissions = genSubmissions(
                b.taskBaseSubmissions,
                isSubscriptions,
                newValue,
                ids,
                newMockup,
                isDesigner
            );
            return {
                ...b,
                taskBaseSubmissions,
            };
        }

        return b;
    });
}

// Update submission for base.
function genSubmissions(
    submissions,
    isSubscriptions,
    newValue,
    ids,
    originValue,
    isDesigner
) {
    const { baseSubId, mockupId, temId } = ids;
    const { message, ...rest } = newValue;

    return (submissions || []).map((sub) => {
        const { id: subId, taskBaseMockups } = sub;

        if (subId === baseSubId) {
            let newMockups = [];

            if (isSubscriptions) {
                newMockups = genMockups(
                    taskBaseMockups,
                    mockupId,
                    rest,
                    originValue
                );
            } else {
                const mockupRemain = getMockupsRemain(taskBaseMockups, temId);
                newMockups = [...mockupRemain, ...message];
            }

            // Get unique by originTemplateId
            // TODO:
            const mockups = isDesigner
                ? newMockups
                : newMockups.filter(
                      (i) =>
                          i.originTemplateId !==
                              originValue?.originTemplateId ||
                          i.id === originValue.id
                  );

            return {
                ...sub,
                taskBaseMockups: mockups,
            };
        }
        return sub;
    });
}

// Gen new mockups with status and output field.
function genMockups(mockups, id, newValue = {}, originValue = {}) {
    // When add new other template
    let cloneMockups = [...(mockups || [])];

    const index = cloneMockups.findIndex((i) => i.id === id);

    return index === -1
        ? [...cloneMockups, originValue]
        : (cloneMockups || []).map((m) => {
              if (m.id === id) {
                  return {
                      ...m,
                      ...newValue,
                  };
              }
              return m;
          });
}

// Filter mockups not gen by template id.
function getMockupsRemain(mockups, id) {
    return (mockups || []).filter((m) => m.originTemplateId !== id);
}

export function splitInternalId(str) {
    if (!str || typeof str !== "string") return [];

    return str.split(/-/);
}

function changeSubmissions(submissions, originTemplateIds) {
    if (!submissions || submissions.length === 0) return [];

    return submissions.map((sub) => {
        const { taskBaseMockups, ...rest } = sub || {};

        const newMockups = (taskBaseMockups || []).filter(
            (i) => !originTemplateIds.includes(i?.originTemplateId)
        );

        return {
            ...rest,
            taskBaseMockups: newMockups,
        };
    });
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    width: 100%;

    .content {
        &-left {
            width: 50%;
            border-right: 0.2rem solid var(--p-border-subdued);
            margin-top: -2rem;
            margin-bottom: -2rem;
            padding: 2rem;
            padding-left: unset;

            .bases-wrap {
                margin-top: 1.6rem;
                display: flex;
                flex-direction: row;
                gap: 2rem;
                flex-wrap: wrap;
            }

            .design-position-wrap {
                margin-top: 1rem;
            }

            .templates-wrap {
                display: flex;
                flex-wrap: wrap;
                gap: 1.6rem;
            }

            .template-wrap {
                width: var(--dimension, 12rem);
                position: relative;
                display: flex;
                flex-direction: column;
                row-gap: 0.75rem;

                &.active {
                    .img-wrap {
                        border-color: var(--p-border-highlight);
                    }
                }

                .img-wrap {
                    border: 0.2rem solid #f6f6f7;
                    border-radius: 5px;
                    width: 100%;
                }

                img {
                    width: 100%;
                    height: var(--dimension, 12rem);
                    border-radius: 3px;
                    object-fit: cover;
                    cursor: pointer;
                    box-shadow: var(
                        --p-card-shadow,
                        0 0 0 1px rgba(63, 63, 68, 0.05),
                        0 1px 3px 0 rgba(63, 63, 68, 0.15)
                    );
                }

                .btn-wrap {
                    position: absolute;
                    top: 0.4rem;
                    right: 0.4rem;
                }

                .title {
                    cursor: pointer;

                    &-wrap {
                        .Polaris-Choice {
                            margin: 0;
                            padding: 0;

                            &__Control {
                                margin-right: 0.5rem;
                            }
                        }
                    }
                }
            }
        }

        &-right {
            flex: 1 1 100%;
        }
    }
`;
