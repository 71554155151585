import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from "react";
import styled from "styled-components";

import { ConfigDesign } from "./ConfigDesign";
import { MockupTemplateProvider } from "../../MockupTemplate/context";

export function MockupDesign() {
    // State
    const [dimension, setDimension] = useState({
        width: 0,
        height: 0,
        percent: 100,
    });
    const wrapRef = useRef(null);

    // Context

    // Handle actions
    const handleResize = useCallback(() => {
        const wrapRect = wrapRef.current?.getBoundingClientRect();
        const wrapWidth = wrapRect?.width;

        setDimension((prev) => ({
            ...prev,
            width: wrapWidth,
            height: wrapWidth,
        }));
    }, []);

    useLayoutEffect(() => {
        handleResize();
    }, [handleResize]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);

    return (
        <MockupTemplateProvider>
            <Wrapper ref={wrapRef}>
                <ConfigDesign />
            </Wrapper>
        </MockupTemplateProvider>
    );
}

const Wrapper = styled.div`
    transition: border-color 0.2s ease-in-out;
    border: 0.2rem solid var(--p-border-highlight);
    border-radius: var(--p-border-radius-wide, 3px);
    width: 100%;
    display: flex;
    flex-direction: row;

    .content {
        background-color: var(--p-surface);
        flex: 1 1;
        border-radius: inherit;
        padding: 2rem;
        width: 80%;
    }
`;
