import React, { useCallback } from "react";
import { TopBar } from "@shopify/polaris";
import { LogOutMinor } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";

import { useAppContext } from "../../context";
import { useToggle } from "../../hooks";

export function TopBarComp({ toggleNavigation }) {
    return (
        <TopBar
            showNavigationToggle
            onNavigationToggle={toggleNavigation}
            userMenu={<UserMenu />}
        />
    );
}

function UserMenu() {
    const { user, logout } = useAppContext();
    const navigate = useNavigate();

    // State
    const [open, toggleOpen] = useToggle(false);

    // Handle action
    const handleLogout = useCallback(() => {
        logout?.();
        navigate("/dashboard");
    }, [logout, navigate]);

    const fullName = [user?.firstName, user?.lastName]
        .filter(Boolean)
        .join(" ");
    const initials = fullName ? fullName.charAt(0) : "";

    return (
        <TopBar.UserMenu
            actions={[
                {
                    items: [
                        {
                            content: "Logout",
                            icon: LogOutMinor,
                            onAction: handleLogout,
                        },
                    ],
                },
            ]}
            name={fullName}
            initials={initials}
            open={open}
            onToggle={toggleOpen}
        />
    );
}
