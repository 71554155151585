import React, { useCallback, useState, useEffect } from "react";
import {
    DropZone,
    Stack,
    Spinner,
    Button,
    Icon,
    buttonFrom,
} from "@shopify/polaris";
import { TickMinor, DeleteMajor } from "@shopify/polaris-icons";
import styled, { css } from "styled-components";
import axios from "axios";

import { API_DOMAIN } from "../../config";
import { useFetch } from "../../hooks";
import { getFile } from "../../utils";

const URL = `${API_DOMAIN}/file/create_presign_url`;
const URL_CREATE_FILE = `${API_DOMAIN}/file/create`;

export function Upload({
    accept = "image/png, image/jpeg",
    multiple,
    onChange,
    type,
    setDisabled = () => {},
    isCustom = false,
    action,
}) {
    // State
    const [files, setFiles] = useState([]);
    const [, setFileList] = useState([]);
    const [loading, setLoading] = useState({});

    // Mutations
    const [createPreSignUrl] = useFetch({ url: URL });
    const [createFile] = useFetch({ url: URL_CREATE_FILE });

    // Handle actions
    // useEffect(() => {
    //     if (onChange && fileList.length > 0) {
    //         onChange(fileList);
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [fileList]);

    useEffect(() => {
        const disabled = Object.values(loading).every((i) => i);
        setDisabled(disabled);
    }, [loading, setDisabled]);

    const checkMimeType = useCallback((file, cb) => {
        const mimes = [
            {
                mime: "image/png",
                pattern: [0x89, 0x50, 0x4e, 0x47],
            },
            {
                mime: "image/jpeg",
                pattern: [0xff, 0xd8, 0xff],
            },
            {
                mime: "image/gif",
                pattern: [0x47, 0x49, 0x46, 0x38],
            },
            {
                mime: "image/webp",
                pattern: [
                    0x52,
                    0x49,
                    0x46,
                    0x46,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    0x57,
                    0x45,
                    0x42,
                    0x50,
                    0x56,
                    0x50,
                ],
            },
        ];
        const isMime = (bytes, mime) => {
            return mime.pattern.every((p, i) => !p || bytes[i] === p);
        };
        const numBytesNeeded = Math.max(...mimes.map((m) => m.pattern.length));
        const blob = file.slice(0, numBytesNeeded);
        const reader = new FileReader();
        reader.onloadend = (e) => {
            if (!e || !reader.result) return cb(null);
            const bytes = new Uint8Array(reader.result);
            const mime = mimes.find((m) => isMime(bytes, m));
            return cb(mime ? mime.mime : null);
        };
        reader.readAsArrayBuffer(blob);
    }, []);

    const customRequest = useCallback(
        (file, index) => {
            setLoading((prev) => ({ ...prev, [index]: true }));
            checkMimeType(file, async (mime) => {
                let fileType = file.type;
                let fileName = file.name;
                if (mime) {
                    fileType = mime;
                    switch (mime) {
                        case "image/webp":
                            fileName = fileName.replace(/\.[^/.]+$/, ".webp");
                            break;
                        case "image/gif":
                            fileName = fileName.replace(/\.[^/.]+$/, ".gif");
                            break;
                        case "image/jpeg":
                            fileName = fileName.replace(/\.[^/.]+$/, ".jpg");
                            break;
                        case "image/png":
                            fileName = fileName.replace(/\.[^/.]+$/, ".png");
                            break;
                        default:
                            break;
                    }
                }
                try {
                    const res = await createPreSignUrl({
                        body: {
                            fileName,
                            mimeType: fileType,
                        },
                    });

                    const { key, url } = res?.data;
                    await axios.put(url, file, {
                        headers: { "Content-Type": fileType },
                        timeout: 1000 * 60 * 30,
                    });

                    const fileRes = await createFile({
                        body: {
                            key,
                            name: fileName,
                            mimeType: fileType,
                            size: file.size,
                        },
                    });

                    const data = fileRes.data;

                    const result = [];
                    if (data != null) {
                        const item = getFile(data);
                        result.push(item);
                        setLoading((prev) => ({ ...prev, [index]: false }));
                    }

                    setFileList((prev) => {
                        const newResult = [...prev, ...result];
                        onChange && onChange(newResult);
                        return newResult;
                    });
                } catch (err) {}
            });
        },
        [checkMimeType, createPreSignUrl, createFile, onChange]
    );

    const handleDrop = useCallback(
        (_droppedFiles, acceptFiles) => {
            setFiles((prev) => [...prev, ...acceptFiles]);

            for (let i = 0; i < acceptFiles.length; i++) {
                customRequest(acceptFiles[i], i);
            }
        },
        [customRequest]
    );
    const handleDelete = useCallback(
        (index) => {
            setFiles((prev) => prev.filter((_item, idx) => index !== idx));
            setFileList((prev) => {
                const newValue = prev.filter((_item, idx) => index !== idx);
                onChange && onChange(newValue);
                return newValue;
            });
        },
        [onChange]
    );

    // Markup
    const fileUpload = <DropZone.FileUpload />;
    const uploadedFiles =
        files.length > 0 ? (
            <Stack vertical>
                {files.map((file, index) => (
                    <div className="image-wrap" key={`image-${index}`}>
                        <div className="image-title-wrap">
                            {loading[index] ? (
                                <Spinner size="small" />
                            ) : (
                                <Icon source={TickMinor} />
                            )}
                            <span>{file.name}</span>
                        </div>
                        <Button
                            plain
                            icon={DeleteMajor}
                            onClick={() => handleDelete(index)}
                        />
                    </div>
                ))}
            </Stack>
        ) : null;

    const loadingBtn = Object.values(loading).some(Boolean);

    return (
        <Wrapper isCustom={isCustom}>
            <DropZone
                accept={accept}
                type={type}
                allowMultiple={multiple}
                onDrop={handleDrop}
            >
                {isCustom
                    ? buttonFrom({ loading: loadingBtn, ...action })
                    : fileUpload}
            </DropZone>
            {!isCustom && <div className="uploaded-wrap">{uploadedFiles}</div>}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    min-height: 50rem;

    ${({ isCustom }) =>
        isCustom &&
        css`
            min-height: auto;
            .Polaris-DropZone {
                min-height: auto;
                min-width: auto;
                display: inline-block;

                &:after {
                    border: none;
                }
            }
        `}

    .uploaded-wrap {
        display: block;
        margin-top: 2rem;
    }

    .image-wrap {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .image-title-wrap {
            display: flex;
            flex-direction: row;
            column-gap: 0.5rem;
        }
    }
`;
