import React, { useCallback } from "react";
import { Badge, Button, Stack } from "@shopify/polaris";
import styled from "styled-components";

// import noImage from "../../../assets/images/img-placeholder.png";
import { CancelSmallMinor } from "@shopify/polaris-icons";
import { useDesignContext } from "../context";

export function Toolbar() {
    // Context
    const {
        templateSelected,
        updateTemplateSelected,
        activate,
        updateActivate,
        updateState,
    } = useDesignContext();

    // Handle actions
    const handleRemove = useCallback(
        (value) => {
            return (event) => {
                event.stopPropagation();
                updateTemplateSelected(value);
            };
        },
        [updateTemplateSelected]
    );

    const handleClick = useCallback(
        (value) => {
            return (event) => {
                event.stopPropagation();
                const id = value?.id;

                updateState({ isEdit: false, preview: false });
                updateActivate(id);
            };
        },
        [updateActivate, updateState]
    );

    return (
        <Stack vertical spacing="tight">
            <Stack vertical spacing="loose">
                {(templateSelected || []).filter(Boolean).map((item, index) => (
                    <Item
                        item={item}
                        key={`item-${item.id}`}
                        index={index}
                        handleRemove={handleRemove}
                        handleClick={handleClick}
                        isActive={activate.includes(item.id)}
                    />
                ))}
            </Stack>
        </Stack>
    );
}

function Item({ item, isActive, handleRemove, handleClick, index }) {
    // const file = item?.file;
    // const dataImage = item?.dataImage;
    // const url = file?.url ? file.url : noImage;
    const cls = isActive && "active";
    const newIndex = index + 1;
    const { title, isCampaign } = item || {};

    return (
        <ItemWrapper className={cls} onClick={handleClick(item)}>
            <div className="content-wrapper">
                <div>
                    <Badge children={newIndex} status="success" />
                </div>
                <div className="content">
                    <div className="btn-remove">
                        <Button
                            icon={CancelSmallMinor}
                            plain
                            onClick={handleRemove(item)}
                        />
                    </div>
                    {/* <img
                    src={dataImage || url}
                    alt=""
                    className="img-wrap"
                    onDragStart={(e) => {
                        e.preventDefault();
                    }}
                /> */}
                    <span title={title} className={"title"}>
                        {title}
                    </span>
                </div>
            </div>
            <div>
                <Badge
                    children={isCampaign ? "Campaign" : "Product"}
                    status="info"
                />
            </div>
        </ItemWrapper>
    );
}

const ItemWrapper = styled.div`
    border-radius: var(--p-border-radius-wide, 3px);
    background-color: var(--p-surface);
    border: 0.2rem solid var(--p-border-subdued);
    outline: 0.1rem solid transparent;
    padding: 2rem 1.6rem;
    display: flex;
    cursor: pointer;
    transition: border-color 0.2s ease-in-out;
    flex-direction: column;
    width: 100%;
    gap: 1rem;

    .content-wrapper {
        display: flex;
        flex-direction: row;
        column-gap: 1rem;spl
    }

    &.active {
        border-color: var(--p-border-highlight);
    }

    .content {
        display: flex;
        flex-direction: row;
        position: relative;
        column-gap: 1rem;
        width: 100%;
    }

    .btn-remove {
        position: absolute;
        top: 0;
        right: 0;
    }

    .img-wrap {
        width: 9rem;
        height: 9rem;
        border-radius: var(--p-border-radius-wide, 3px);
        object-fit: cover;
    }

    .title {
        display: -webkit-box;
        max-width: 200px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
`;
