import React, {
    createContext,
    useCallback,
    useContext,
    useReducer,
} from "react";
import { match } from "../../../utils";
// import { data } from "../mock";

export const STEP = {
    ChooseTemplate: "ChooseTemplate",
    CreateMockup: "CreateMockup",
};

export const ActionType = {
    UpdateState: "UpdateState",
};

const reducers = {
    [ActionType.UpdateState](state, action) {
        return {
            ...state,
            ...action.payload,
        };
    },
};

function stateReducer(state, action) {
    return match(action.type, reducers, state, action);
}

const DesignContext = createContext(null);
DesignContext.displayName = "DesignContext";

const initialValue = {
    step: STEP.CreateMockup,
    isChooseTemplate: false,
    templateSelected: [],
    activate: [],
    isEdit: false,
    templateActive: null,
    baseActive: null,
    mockupActive: null,
    preview: false,
    loading: false,
    baseLoading: [], // loading Other mockup mutation
    templateChoices: [],
    changedLayer: false,
    mainMockup: null,
};

export function DesignContextProvider({ children, ...props }) {
    const [state, dispatch] = useReducer(stateReducer, initialValue);

    // Handle action
    const updateState = useCallback(
        (newState) => {
            const cloneState = Object.assign(
                {},
                JSON.parse(JSON.stringify(newState))
            );

            dispatch({
                type: ActionType.UpdateState,
                payload: cloneState,
            });
        },
        [dispatch]
    );
    const updateActivate = useCallback(
        (id) => {
            let activate = state.activate;
            const index = activate.findIndex((i) => i.id === id);
            if (index !== -1) {
                activate.splice(index, 1);
            }
            activate = [id];

            dispatch({
                type: ActionType.UpdateState,
                payload: { activate },
            });

            const templateActive = state.templateSelected.find(
                (i) => i.id === id
            );
            dispatch({
                type: ActionType.UpdateState,
                payload: {
                    templateActive,
                },
            });
        },
        [state, dispatch]
    );

    const updateTemplateSelected = useCallback(
        (selected, addDataImage = false) => {
            let templateSelected = state?.templateSelected;

            if (addDataImage) {
                templateSelected = selected;
            } else {
                if (Array.isArray(selected)) {
                    for (let i = 0; i < selected.length; i++) {
                        const item = selected[i];
                        const { data } = updateSelected(templateSelected, item);
                        templateSelected = data;
                    }
                } else {
                    const { data, index } = updateSelected(
                        templateSelected,
                        selected
                    );
                    templateSelected = data;
                    if (index !== -1) {
                        const newIndex = index ? index - 1 : 0;
                        const id = data[newIndex]?.id;
                        updateActivate(id);
                    } else {
                        const id = data[0]?.id;
                        updateActivate(id);
                    }
                }
            }

            dispatch({
                type: ActionType.UpdateState,
                payload: { templateSelected },
            });
        },
        [state, dispatch, updateActivate]
    );

    const updateTemplateChoices = useCallback(
        (tmpId) => {
            const choices = state.templateChoices;
            const index = choices.findIndex((t) => t === tmpId);

            if (index > -1) {
                choices.splice(index, 1);
            }

            choices.push(tmpId);

            dispatch({
                type: ActionType.UpdateState,
                payload: { templateChoices: choices },
            });
        },
        [dispatch, state]
    );

    const updateLoadingOM = useCallback(
        (baseId) => {
            if (baseId != null) {
                let cloneBaseLoading = [...state.baseLoading];
                const index = cloneBaseLoading.findIndex((i) => i === baseId);

                if (index > -1) {
                    cloneBaseLoading.splice(index, 1);
                } else {
                    cloneBaseLoading = [baseId];
                }

                dispatch({
                    type: ActionType.UpdateState,
                    payload: { baseLoading: cloneBaseLoading },
                });
            }
        },
        [dispatch, state]
    );

    return (
        <DesignContext.Provider
            value={{
                ...state,
                updateState,
                updateTemplateSelected,
                updateActivate,
                updateTemplateChoices,
                updateLoadingOM,
                ...props,
            }}
        >
            {children}
        </DesignContext.Provider>
    );
}

function updateSelected(data, item) {
    if (!Array.isArray(data) || !item) return [];
    const index = data.findIndex((i) => i.id === item.id);
    if (index !== -1) {
        data.splice(index, 1);
    } else {
        data.push(item);
    }

    return { data, index };
}
export function useDesignContext() {
    return useContext(DesignContext);
}
