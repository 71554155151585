import { Checkbox, Collapsible, Heading, Spinner } from "@shopify/polaris";
import React, { useCallback, useMemo } from "react";
import { useDesignContext } from "../context";
import { resetMainMockup, splitInternalId } from "./ConfigDesign";
import styled from "styled-components";
import { cloneDeep } from "lodash";

export function GeneratedMockups() {
    const {
        baseActive,
        templateActive,
        updateState,
        setCloneBases,
        cloneBases,
    } = useDesignContext() || {};

    const { internalId, id: baseActiveId } = baseActive || {};
    const [templateId, baseConfigId] = splitInternalId(internalId);

    const matchTemplates = useMemo(() => {
        if (
            !cloneBases ||
            cloneBases.length === 0 ||
            templateActive == null ||
            templateActive.id !== templateId
        )
            return [];

        const curBase = [...cloneBases].find(
            (i) =>
                i.taskBaseGroupId === templateId &&
                i.baseConfigId === baseConfigId
        );

        if (curBase != null) {
            return curBase.matchTemplates;
        }

        return [];
    }, [cloneBases, templateActive, baseConfigId, templateId]);
    const curTemp = (matchTemplates || []).find((m) => m.id === baseActive?.id);
    const curTempId = curTemp?.id;

    // Actions
    const updateTemplates = useCallback(
        (templates, clone) => {
            if (!templates || templates.length === 0) return [];
            return templates.map((t) => {
                if (t.id === curTempId) {
                    return {
                        ...t,
                        mockups: clone,
                    };
                }

                return t;
            });
        },
        [curTempId]
    );

    function resetActive(arr) {
        if (!arr || arr.length === 0) return [];
        return arr.map((i) => ({ ...i, active: false }));
    }

    const updateBasesClone = useCallback(
        (bases, clone) => {
            if (!bases || bases.length === 0) return [];

            return bases.map((b) => {
                const { matchTemplates } = b || {};
                if (b.id === baseConfigId) {
                    return {
                        ...b,
                        matchTemplates: updateTemplates(matchTemplates, clone),
                    };
                }

                return {
                    ...b,
                    matchTemplates: (matchTemplates || []).map((tmp) => ({
                        ...tmp,
                        mockups: resetActive(tmp.mockups),
                    })),
                };
            });
        },
        [baseConfigId, updateTemplates]
    );

    const handleViewMockup = useCallback(
        (mockup) => {
            return (event) => {
                event.stopPropagation();
                if (mockup.active) return;

                let cloneMockup = { ...(mockup || {}) };
                const clone = [...(curTemp?.mockups || [])].map((i) => {
                    if (i.id === mockup.id) {
                        cloneMockup = {
                            ...i,
                            active: !i.active,
                        };
                        return cloneMockup;
                    }
                    return {
                        ...i,
                        active: false,
                    };
                });

                baseActive.mockups = clone;
                const newState = {
                    baseActive,
                    mockupActive: cloneMockup,
                    isEdit: false,
                    preview: false,
                };
                if (!cloneMockup.active) {
                    newState.mockupActive = null;
                }
                updateState(newState);

                setCloneBases((prev) => {
                    return updateBasesClone?.(prev, clone);
                });
            };
        },
        [baseActive, updateState, curTemp, setCloneBases, updateBasesClone]
    );

    const handleChooseMockup = useCallback(
        (mockup) => {
            return (value) => {
                setCloneBases((prev) => {
                    const cloneBases = updateMainMockup(
                        prev,
                        baseConfigId,
                        baseActiveId,
                        mockup,
                        value
                    );
                    return cloneBases;
                });
            };
        },
        [setCloneBases, baseConfigId, baseActiveId]
    );

    return (
        <Collapsible
            id={`${templateId}-${baseConfigId}-${curTemp?.id}`}
            open={!!curTemp?.active}
            expandOnPrint
            transition={{
                duration: "500ms",
                timingFunction: "ease-in-out",
            }}
        >
            {curTemp != null && (
                <Wrapper>
                    <div className="mockups-wrap">
                        <Heading element="h3" children="Generated mockups" />
                        <div className="mockups-inner">
                            {(curTemp.mockups || []).map((m, index) => (
                                <MockupItem
                                    value={m}
                                    key={`mockup-${index}`}
                                    onViewMockup={handleViewMockup(m)}
                                    onChooseMockup={handleChooseMockup(m)}
                                />
                            ))}
                        </div>
                    </div>
                </Wrapper>
            )}
        </Collapsible>
    );
}

const Pending = "Pending";
function MockupItem({ value, onViewMockup, onChooseMockup }) {
    const { outputMockup, status, active, isMainMockup } = value;
    const isPending = [Pending].includes(status);

    const file = outputMockup;
    const src = file
        ? file.thumbnailUrl
            ? file.thumbnailUrl
            : file.url
            ? file.url
            : null
        : null;

    const activeCls = active ? "active" : "";
    return (
        <div className={`mockup-wrap ${activeCls}`} onClick={onViewMockup}>
            <div
                className="choose-main-mockup"
                onClick={(evt) => evt.stopPropagation()}
            >
                <Checkbox checked={isMainMockup} onChange={onChooseMockup} />
            </div>
            {isPending ? (
                <div className="overlay img-wrap">
                    <Spinner />
                </div>
            ) : (
                <div className="img-wrap">
                    <img
                        src={src}
                        alt=""
                        onDragStart={(e) => e.preventDefault()}
                    />
                </div>
            )}
        </div>
    );
}

function updateMainMockup(
    bases,
    baseConfigId,
    baseActiveId,
    curMockup,
    checked
) {
    const cloneBases = cloneDeep(bases);

    if (!cloneBases || cloneBases.length === 0) return;

    return cloneBases.map((b) => {
        const { matchTemplates } = b || {};
        if (b.id === baseConfigId) {
            return {
                ...b,
                matchTemplates: (matchTemplates || []).map((mt) => {
                    if (mt.id === baseActiveId) {
                        const { mockups } = mt || {};
                        const targetId = curMockup?.id;

                        const cloneMockups = calculateMainMockup(
                            mockups,
                            targetId,
                            checked
                        );

                        return {
                            ...mt,
                            mockups: cloneMockups,
                            isMainMockup: (cloneMockups || []).some(
                                (m) => m.isMainMockup
                            ),
                        };
                    }

                    return {
                        ...mt,
                        // Reset `isMainMockup` = false.
                        isMainMockup: false,
                        mockups: resetMainMockup(mt.mockups),
                    };
                }),
            };
        }

        return {
            ...b,
            matchTemplates: (b.matchTemplates || []).map((tm) => {
                const { mockups } = tm;
                const newMockups = resetMainMockup(mockups);

                return {
                    ...tm,
                    mockups: newMockups,
                    isMainMockup: false,
                };
            }),
        };
    });
}

export function calculateMainMockup(data, targetId, checked) {
    let cloneData = cloneDeep(data);
    if (!(cloneData instanceof Array) || cloneData.length === 0) return [];

    const mainMockup = cloneData.filter((i) => i.isMainMockup);
    const len = mainMockup.length;

    // If not yet mockup is main
    if (len === 0) {
        cloneData = cloneData.map((m) => {
            if (m.id === targetId) {
                return {
                    ...m,
                    isMainMockup: checked,
                };
            }

            return m;
        });
        // have main mockup
    } else if (checked) {
        cloneData = cloneData.map((m) => {
            if (m.id === targetId) {
                return {
                    ...m,
                    isMainMockup: checked,
                };
            }

            return {
                ...m,
                isMainMockup: false,
            };
        });
    }

    return cloneData;
}

const Wrapper = styled.div`
    margin-top: 1.6rem;

    .mockups {
        &-wrap {
            margin-top: 2rem;
            display: flex;
            flex-direction: column;

            .overlay {
                width: 100%;
                height: var(--dimension, 12rem);
                display: inherit;
                justify-content: center;
                align-items: center;
                background-color: #e7e7e7;
                border-radius: 5px;
                cursor: pointer;
            }
        }
        &-inner {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            gap: 1.6rem;
            margin-top: 1.6rem;
        }
    }

    .mockup-wrap {
        width: var(--dimension, 12rem);
        position: relative;
        display: flex;
        flex-direction: column;
        row-gap: 0.75rem;

        &.active {
            .img-wrap {
                border-color: var(--p-border-highlight);
            }
        }

        .img-wrap {
            border: 0.2rem solid #f6f6f7;
            border-radius: 5px;
            width: 100%;
        }

        img {
            width: 100%;
            height: var(--dimension, 12rem);
            border-radius: 3px;
            object-fit: cover;
            cursor: pointer;
            box-shadow: var(
                --p-card-shadow,
                0 0 0 1px rgba(63, 63, 68, 0.05),
                0 1px 3px 0 rgba(63, 63, 68, 0.15)
            );
        }

        .btn-wrap {
            position: absolute;
            top: 0.4rem;
            right: 0.4rem;
        }

        .title {
            cursor: pointer;
        }

        .choose-main-mockup {
            position: absolute;
            top: 0.1rem;
            left: 0.1rem;

            .Polaris-Choice {
                padding: 0;
                margin: 0;
            }
        }
    }
`;
