import React, { useCallback } from "react";
import { Button, TextContainer, TextStyle } from "@shopify/polaris";
import styled from "styled-components";

import noImg from "../../assets/images/img-placeholder.png";
import { formatFileSize } from "../../utils";
import { API_DOMAIN } from "../../config";
import { useFetch } from "../../hooks";
import { useToastContext } from "../Toast";

const URL = `${API_DOMAIN}/file/delete`;

export const FileDetail = ({ value, isImage, setImages, setFiles }) => {
    // Context
    const { toggleToast, setNotify } = useToastContext();

    // Mutation
    const [deleteFile, { loading }] = useFetch({ url: URL, method: "delete" });

    const handleDelete = useCallback(async () => {
        let id = value?.id;
        if (id) {
            toggleToast?.(true);
            try {
                const data = await deleteFile({
                    body: {
                        ids: [id],
                    },
                });
                const success = data?.data?.success;
                if (success?.length > 0) {
                    setImages((prev) => prev.filter((i) => i.id !== id));
                    setFiles((prev) => prev.filter((i) => i.id !== id));
                    setNotify({ msg: "Delete file successfully.", err: false });
                }
            } catch (err) {
                setNotify({ msg: err?.toString(), err: true });
            }
        }
    }, [value, deleteFile, setImages, setFiles, toggleToast, setNotify]);

    const src = value?.url
        ? value.url
        : value?.thumbnailUrl
        ? value.thumbnailUrl
        : noImg;

    return (
        <Wrapper>
            <TextContainer>
                <div className="thumbnail">
                    {isImage ? (
                        <img style={{ width: "100%" }} alt="" src={src} />
                    ) : (
                        <img style={{ width: "100%" }} alt={""} src={noImg} />
                    )}
                </div>
                <div className="desc">
                    <TextStyle>{value && value.name}</TextStyle>
                    <TextStyle>{value && formatFileSize(value.size)}</TextStyle>
                    <Button
                        children="Delete permanently"
                        plain
                        destructive
                        onClick={handleDelete}
                        loading={loading}
                    />
                </div>
            </TextContainer>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding: 1.6rem;

    .desc {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;

        .Polaris-Button {
            justify-content: start;
        }
    }
`;
