import React, {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { API_DOMAIN } from "../../config";
import { useAppContext } from "../../context";
import { useFetch, useToggle } from "../../hooks";
import { useToastContext } from "../Toast";
import { ModalMedia } from "../Media";

import { Form } from "./Form";

const URL = `${API_DOMAIN}/template/create`;

export function Create({ url, isIFrame }) {
    // Context
    const { toggleToast, setNotify } = useToastContext();
    const { file } = useAppContext();
    const navigate = useNavigate();
    const timeRef = useRef(null);

    // State
    const [open, toggleOpen] = useToggle(false);
    const [postMessage, setPostMessage] = useState({
        loading: null,
        msg: null,
    });

    // Mutation
    const [createMockup, { loading }] = useFetch({ url: URL });

    // Handle actions
    const handleSubmit = useCallback(
        async (input) => {
            toggleToast?.(true);
            setNotify?.({ msg: null, err: false })

            try {
                setPostMessage((prev) => ({ ...prev, loading: true, msg: "" }));
                const { data, error } = await createMockup({ body: input });
                if (data != null && !error) {
                    setNotify({
                        msg: "Create template successfully.",
                        err: false,
                    });

                    if (!isIFrame) {
                        timeRef.current = setTimeout(() => {
                            navigate(url);
                        }, 1000);
                    } else {
                        setPostMessage((prev) => ({
                            ...prev,
                            loading: false,
                            msg: "Completed",
                        }));
                    }
                } else {
                    setNotify({
                        msg: error?.toString(),
                        err: true,
                    });
                    setPostMessage((prev) => ({
                        ...prev,
                        loading: false,
                        msg: "",
                    }));
                }
            } catch (err) {
                setNotify({ msg: err.toString(), err: true });
            }
        },
        [createMockup, setNotify, toggleToast, navigate, url, isIFrame]
    );

    useEffect(() => () => clearTimeout(timeRef.current), []);
    useEffect(() => {
        window?.parent?.postMessage(JSON.stringify(postMessage), "*");
    }, [postMessage]);

    useEffect(() => {
        if (file == null) {
            toggleOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <Form
                url={url}
                onSubmit={handleSubmit}
                loading={loading}
                isIFrame={isIFrame}
            />
            <ChooseImage open={open} onClose={toggleOpen} />
        </Fragment>
    );
}

function ChooseImage({ open, onClose }) {
    // State
    const [files, setFiles] = useState([]);

    const { selectFile } = useAppContext();

    // Handle actions
    const handleSelect = useCallback(() => {
        const [file] = files?.filter(Boolean);

        selectFile(file);
    }, [files, selectFile]);

    return (
        <ModalMedia
            open={open}
            onClose={onClose}
            files={files}
            setFiles={setFiles}
            onSelect={handleSelect}
        />
    );
}
