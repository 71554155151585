import React from "react";
import { Link } from "react-router-dom";

export const LinkComp = ({ children, url = "", ...rest }) => {
    // Use an regular a tag for external and download links
    if (isOutboundLink(url) || rest.download) {
        return (
            <a href={url} {...rest}>
                {children}
            </a>
        );
    }

    return (
        <Link to={url} {...rest}>
            {children}
        </Link>
    );
};

function isOutboundLink(url) {
    return /^(?:[a-z][a-z\d+.-]*:|\/\/)/.test(url);
}
