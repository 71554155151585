import React from "react";
import styled from "styled-components";

const Container = styled.div`
    .required {
        &:after {
            content: "*";
            color: red;
            margin-left: 0.25rem;
        }
    }
    .Polaris-Button__Icon {
        margin-right: 0.2rem;
        .Polaris-Icon {
            height: 1.5rem;
            width: 1.5rem;
        }
    }
`;

// Variable
const LABEL = "Polaris-Label";
const LABEL_WRAP = LABEL + "led__LabelWrapper";
const LABEL_TEXT = LABEL + "__Text";
const LABEL_ACTION = LABEL + "led__Action";

export function Labelled({ label, required, hasAction, children }) {
    return (
        <Container>
            <div className={LABEL_WRAP}>
                <div className={LABEL}>
                    <label
                        className={`${LABEL_TEXT} ${
                            required ? "required" : ""
                        }`}
                    >
                        {label}
                    </label>
                </div>
                {hasAction && <div className={LABEL_ACTION}>{children}</div>}
            </div>
        </Container>
    );
}
