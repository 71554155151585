import React from "react";
import { EmptyState } from "@shopify/polaris";
import noItem from "../../assets/images/no-items-found.png";
import styled from "styled-components";

const Container = styled.div`
    margin: 0 auto;
    &.slim {
        .Polaris-EmptyState {
            padding: 0;
        }
    }
`;

export function EmptyStateComp({ noData, slim }) {
    return (
        <Container className={slim ? "slim" : ""}>
            <EmptyState
                heading={`${noData ? "No data" : "No items found"}`}
                image={noItem}
            >
                <p>Try changing the filters or search item</p>
            </EmptyState>
        </Container>
    );
}
