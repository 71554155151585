import React, { useCallback, useState } from "react";
import {
    Button,
    Labelled,
    Stack,
    TextField,
    TextStyle,
} from "@shopify/polaris";
import { FolderPlusMajor } from "@shopify/polaris-icons";

import { useToggle } from "../../hooks";
import { ModalMedia } from "../Media";

export function LayerSetting({ id, files, updateFiles, percent }) {
    // State
    const [open, toggleOpen] = useToggle(false);
    const [values, setValues] = useState([]);
    const [dimension, setDimension] = useState({
        width: "",
        height: "",
    });

    // Handle action
    const handleDimensionChange = useCallback((value, id) => {
        setDimension((prev) => ({ ...prev, [id]: value }));
    }, []);
    const handleBlur = useCallback(() => {
        if (Object.values(dimension).every((v) => !v)) return;
        const { width, height } = dimension;
        let newValue = {};
        newValue.width = width ? (parseFloat(width) / 100) * percent : 0;
        newValue.height = height ? (parseFloat(height) / 100) * percent : 0;

        newValue = Object.entries(newValue).reduce((acc, [key, value]) => {
            if (value) {
                acc[key] = value;
            }
            return acc;
        }, {});

        const newFiles = matchFiles(files, id, newValue);
        updateFiles(newFiles);
    }, [dimension, files, id, updateFiles, percent]);

    const handleSelect = useCallback(() => {
        const [file] = values;
        const newValue = {
            imageUrl: file.url,
            fileId: file.id,
        };

        const newFiles = matchFiles(files, id, newValue);
        updateFiles(newFiles);
    }, [values, files, id, updateFiles]);

    return (
        <Stack vertical spacing="tight">
            <Labelled label="Layer settings" />
            <Stack spacing="tight" wrap={false} distribution="fill">
                <TextField
                    type="number"
                    value={dimension.width}
                    label="Width"
                    onChange={handleDimensionChange}
                    onBlur={handleBlur}
                    id="width"
                    placeholder="1000px"
                    
                />
                <TextField
                    type="number"
                    value={dimension.height}
                    label="Height"
                    onChange={handleDimensionChange}
                    onBlur={handleBlur}
                    id="height"
                    placeholder="1000px"
                />
            </Stack>
            <TextStyle
                variation="subdued"
                children="Preview width a design file"
            />
            <Button
                fullWidth
                icon={FolderPlusMajor}
                children="Select File"
                size="slim"
                onClick={toggleOpen}
            />
            <ModalMedia
                open={open}
                files={values}
                onClose={toggleOpen}
                setFiles={setValues}
                onSelect={handleSelect}
            />
        </Stack>
    );
}

function matchFiles(arr, id, newValue) {
    if (!arr || arr.length === 0) return [];

    return arr.map((item) => {
        if (item.id === id) {
            return {
                ...item,
                ...newValue,
            };
        }
        return item;
    });
}
