import { useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Frame } from "@shopify/polaris";
import styled from "styled-components";

import { ToastContextProvider } from "../components/Toast";
import { Sidebar } from "./sidebar";

import { useToggle } from "../hooks";
import { TopBarComp } from "./top-bar";
import { useAppContext } from "../context";

export function FrameComp({ children }) {
    const location = useLocation();

    // Context
    const { user, isIFrame } = useAppContext();

    // state
    const [open, toggleOpen] = useToggle(false);
    // ref
    const skipToContentRef = useRef(null);

    // Markup
    const skipToContentTarget = (
        <a
            id="skipToContentTarget"
            ref={skipToContentRef}
            href="foo"
            tabIndex={-1}
        >
            {" "}
        </a>
    );

    const hasMenu = useMemo(() => user != null && !isIFrame, [user, isIFrame]);

    const topBarMarkup = hasMenu && (
        <TopBarComp toggleNavigation={toggleOpen} />
    );

    const navigationMarkup = hasMenu && (
        <Sidebar pathname={location?.pathname ?? "/dashboard"} />
    );

    return (
        <Wrapper hasMenu={hasMenu}>
            <Frame
                topBar={topBarMarkup}
                showMobileNavigation={open}
                onNavigationDismiss={toggleOpen}
                navigation={navigationMarkup}
                skipToContentTarget={skipToContentRef.current}
            >
                {skipToContentTarget}
                <ToastContextProvider>{children}</ToastContextProvider>
            </Frame>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    .Polaris-Frame {
        background-color: ${({ hasMenu }) => (hasMenu ? undefined : "#fff")};
    }
`;
