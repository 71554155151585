import { useCallback, useRef } from "react";
import { Group, Image, Rect } from "react-konva";
import useImage from "use-image";

import { Transformer } from "./Transformer";

export function GroupComp({ shape, onChange = () => {}, isEdit, preview }) {
    // Ref
    const groupRef = useRef(null);
    const imgRef = useRef(null);

    // Props
    const imageUrl = shape.imageUrl;
    const [image] = useImage(imageUrl);

    // Handle actions
    const handleDragMove = useCallback(() => {
        const group = groupRef.current;
        const image = imgRef.current;
        const imgX = image.x();
        const imgY = image.y();

        const pos = group.absolutePosition();
        pos.width = pos.x + group.width();
        pos.height = pos.y + group.height();

        // Max width || max height
        const maxX = pos.width - image.width() - shape.x;
        const maxY = pos.height - image.height() - shape.y;

        // If `x` less than 0 or greater than `maxX` => re-set `x`
        if (imgX < 0) {
            image.x(Math.round(Math.max(0, imgX)));
        } else if (maxX <= imgX) {
            image.x(Math.round(maxX));
        }

        // If `y` less than 0 or greater than `maxY` => re-set `y`
        if (imgY < 0) {
            image.y(Math.round(Math.max(0, imgY)));
        } else if (maxY <= imgY) {
            image.y(Math.round(maxY));
        }
    }, [shape]);

    const handleDragMoveEnd = useCallback(() => {
        const node = imgRef.current;
        const attrs = node?.attrs;
        const pos = node?.absolutePosition();
        if (pos != null && attrs != null) {
            const { x, y } = pos;
            const { width, height, rotation } = attrs;
            onChange({
                ...shape,
                x,
                y,
                width,
                height,
                rotation: Math.max(0, rotation || 0),
            });
        }
    }, [shape, onChange]);

    const handleTransform = useCallback(() => {
        const node = imgRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        const rotation = node.rotation();

        // We will rest it back
        node.scaleX(1);
        node.scaleY(1);

        let width = Math.max(5, node.width() * scaleX);
        let height = Math.max(5, node.height() * scaleY);

        const group = groupRef.current;
        let x = Math.round(Math.max(0, node.x()));
        let y = Math.round(Math.max(0, node.y()));

        width = Math.min(width, group.width() - x);
        height = Math.min(height, group.height() - y);

        node.x(x);
        node.y(y);
        node.width(width);
        node.height(height);
        node.rotation(rotation);
    }, []);

    const handleTransformEnd = useCallback(() => {
        const node = imgRef.current;
        const attrs = node?.attrs;
        const pos = node?.absolutePosition();
        if (attrs != null) {
            const { width, height, rotation } = attrs;
            const { x, y } = pos;
            onChange({
                ...shape,
                x,
                y,
                width,
                height,
                rotation: Math.max(0, rotation || 0),
            });
        }
    }, [shape, onChange]);
    const haveTransformer = (!preview && !isEdit) || !isEdit;
    return (
        <Group ref={groupRef} {...shape}>
            {isEdit && (
                <Rect fill="green" {...shape} opacity={0.4} x={0} y={0} />
            )}
            <Image
                ref={imgRef}
                image={image}
                width={shape.width - 20}
                height={shape.height - 20}
                x={10}
                y={10}
                onDragMove={handleDragMove}
                onDragEnd={handleDragMoveEnd}
                id={shape.elName}
                onTransform={handleTransform}
                onTransformEnd={handleTransformEnd}
            />
            <Transformer
                selectedLayers={[shape.elName]}
                haveTransformer={haveTransformer}
            />
        </Group>
    );
}
