import React, { useCallback, useEffect, useRef, useState } from "react";
import { Stage, Layer, Rect } from "react-konva";
import { IFRAME_MESSAGE_TYPE } from "../../constants";

import { useDesignContext } from "../Design/context";
import { useMockupTemplateCtx } from "../MockupTemplate/context";

import { Element } from "./Element";

export function Box() {
    // Context
    const {
        files,
        width,
        height,
        backgroundColor,
        updateFiles,
        updateState,
        fileActive,
    } = useMockupTemplateCtx();
    const {
        updateState: updateStateD,
        isEdit,
        preview,
        changedLayer,
    } = useDesignContext() || {};

    // State
    const [selected, setSelected] = useState(null);
    const [dimension, setDimension] = useState({
        width: 0,
        height: 0,
        percent: 100,
    });
    const wrapRef = useRef(null);
    const canUpdate = useRef(false);
    const changedLayerRef = useRef(changedLayer);

    // Handle action
    const handleDeselect = useCallback((e) => {
        // Deselect when clicked on empty area
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            setSelected(null);
        }
    }, []);

    // const isDesign = MOCKUP_TYPE.Design;
    const handleChange = useCallback(
        (attrs, index) => {
            const newFiles = files.map((file, idx) => {
                if (index === idx) {
                    return {
                        ...file,
                        ...attrs,
                    };
                }
                return file;
            });
            updateFiles(newFiles);

            // if (mockupActive != null) {
            //     mockupActive.layers = newFiles;
            //     updateStateD?.({ mockupActive, preview: true });
            // }
            updateStateD?.({ preview: true });
            if (!changedLayerRef.current) {
                changedLayerRef.current = true;
                const data = {
                    type: IFRAME_MESSAGE_TYPE.ChangedLayer,
                    input: true,
                };
                window?.parent?.postMessage(JSON.stringify(data), "*");
            }
        },
        [updateFiles, files, updateStateD]
    );

    const handleResize = useCallback(() => {
        const wrapRect = wrapRef.current?.getBoundingClientRect();
        const wrapWidth = wrapRect?.width;
        let wrapHeight = wrapRect?.height;

        const percent = wrapWidth ? (wrapWidth / width) * 100 : 100;
        if (percent) {
            wrapHeight = (height / 100) * percent;
        }

        setDimension((prev) => ({
            ...prev,
            width: wrapWidth,
            // width: width,
            height: wrapHeight,
            // height,
        }));

        updateState({
            relativeWidth: wrapWidth,
            relativeHeight: wrapHeight,
            percent,
        });
        canUpdate.current = true;
    }, [width, height, updateState]);

    useEffect(() => {
        handleResize();
    }, [handleResize]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [handleResize]);

    useEffect(() => {
        const wrapWidth = dimension.width;
        const wrapHeight = dimension.height;
        const wrapRect = wrapRef.current?.getBoundingClientRect();

        const withMap = wrapHeight === wrapRect?.width;
        const heightMap = wrapHeight === wrapRect?.height;
        if (canUpdate.current && heightMap && withMap && files?.length > 0) {
            const newFiles = files.map((file, index) => {
                if (!index) {
                    return {
                        ...file,
                        width: wrapWidth,
                        height: wrapHeight,
                    };
                }
                return file;
            });

            updateFiles(newFiles);
            canUpdate.current = false;
        }
    }, [dimension, updateFiles, files]);

    const selectedLayers = Object.entries(fileActive).reduce(
        (acc, [key, value]) => (value ? [...acc, key] : acc),
        []
    );

    return (
        <div ref={wrapRef}>
            <Stage
                width={dimension.width}
                height={dimension.height}
                onMouseDown={handleDeselect}
                onTouchStart={handleDeselect}
            >
                <Layer>
                    <Rect
                        x={0}
                        y={0}
                        width={dimension.width}
                        height={dimension.height}
                        fill={backgroundColor ?? "#333"}
                        strokeWidth={3}
                    />
                    {files?.length > 0 &&
                        files.map((rect, index) => (
                            <Element
                                key={index}
                                shape={rect}
                                isSelected={rect.id === selected}
                                imageUrl={rect.imageUrl}
                                elName={rect.elName}
                                onSelect={() => setSelected(rect.id)}
                                selectedLayers={selectedLayers}
                                // isConfigDesign={isConfigDesign}
                                isEdit={isEdit}
                                preview={preview}
                                onChange={(newAttrs) =>
                                    handleChange(newAttrs, index)
                                }
                            />
                        ))}
                </Layer>
            </Stage>
        </div>
    );
}
