import { Toast } from "@shopify/polaris";
import React, { createContext, useContext, useMemo, useState } from "react";

import { useToggle } from "../../hooks";

const ToastContext = createContext(null);
ToastContext.displayName = "ToastContext";

export function ToastContextProvider({ children }) {
    // State
    const [open, toggleOpen] = useToggle(false);
    const [notify, setNotify] = useState({ msg: null, err: false });

    const bag = useMemo(
        () => ({
            toggleToast: toggleOpen,
            setNotify,
        }),
        [toggleOpen, setNotify]
    );

    const toastMarkup = open && notify.msg && (
        <Toast
            content={notify.msg}
            error={notify.err}
            duration={1500}
            onDismiss={toggleOpen}
        />
    );

    return (
        <ToastContext.Provider value={bag}>
            {toastMarkup}
            {children}
        </ToastContext.Provider>
    );
}

export function useToastContext() {
    return useContext(ToastContext);
}
