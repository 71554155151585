import React, { Fragment, useCallback, useEffect, useRef } from "react";
import useImage from "use-image";
import { Group } from "react-konva";

import { Transformer } from "./Transformer";
import { isTypeDesign } from "../../utils";
import { GroupComp } from "./GroupComp";
import { Key } from "../../constants";

const DELTA = 1;

export function Element({
    shape,
    onSelect,
    onChange,
    imageUrl,
    selectedLayers,
    isConfigDesign,
    isEdit,
    preview,
}) {
    // Refs
    const shapeRef = useRef();
    const id = shape?.id;
    const active = (selectedLayers || []).includes(id);

    // Handle actions
    const handleDrag = useCallback(
        (e) => {
            onChange?.({
                ...shape,
                x: e.target.x(),
                y: e.target.y(),
            });
        },
        [shape, onChange]
    );

    const handleTransform = useCallback(() => {
        // transformer is changing scale of the node
        // and NOT its width or height
        // but in the store we have only width and height
        // to match the data better we will reset scale on transform end
        const node = shapeRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        const rotation = node.rotation();

        // we will reset it back
        node.scaleX(1);
        node.scaleY(1);
        onChange({
            ...shape,
            x: node.x(),
            y: node.y(),
            // set minimal value
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(5, node.height() * scaleY),
            rotation,
        });
    }, [shape, onChange]);

    const [image] = useImage(imageUrl);
    const ComponentName = imageUrl?.length > 0 ? "Image" : "Rect";

    const isDesign = isTypeDesign(shape.type);

    useEffect(() => {
        if (!shapeRef.current || !active) return;

        const handler = (event) => {
            const { keyCode } = event;

            switch (keyCode) {
                case Key.LeftArrow:
                    shapeRef.current.x(shapeRef.current.x() - DELTA);
                    break;
                case Key.UpArrow:
                    shapeRef.current.y(shapeRef.current.y() - DELTA);
                    break;
                case Key.RightArrow:
                    shapeRef.current.x(shapeRef.current.x() + DELTA);
                    break;
                case Key.DownArrow:
                    shapeRef.current.y(shapeRef.current.y() + DELTA);
                    break;
                default:
                    break;
            }

            const newPosition = {
                x: shapeRef.current.x(),
                y: shapeRef.current.y(),
            };

            onChange({
                ...shape,
                x: newPosition.x,
                y: newPosition.y,
            });
        };

        document.addEventListener("keydown", handler);

        return () => {
            document.removeEventListener("keydown", handler, false);
        };
    }, [active, shape, onChange]);

    return isDesign && isConfigDesign ? (
        <GroupComp
            shape={shape}
            onChange={onChange}
            isEdit={isEdit}
            preview={preview}
        />
    ) : (
        <Fragment>
            <ComponentName
                image={image}
                onClick={onSelect}
                onTap={onSelect}
                ref={shapeRef}
                {...shape}
                onDragEnd={handleDrag}
                onTransformEnd={handleTransform}
            />
            <Group>
                <Transformer selectedLayers={selectedLayers} />
            </Group>
        </Fragment>
    );
}
