import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Card, Modal, Tabs } from "@shopify/polaris";

import { ImportUrls } from "./ImportUrls";
import { Library } from "./Library";
import { Upload } from "./Upload";

import { API_DOMAIN } from "../../config";
import { useFetch } from "../../hooks";
import { getFile } from "../../utils";

const URL = `${API_DOMAIN}/file/import_url`;

export function ModalMedia({
    open,
    onClose,
    multiple,
    files,
    setFiles,
    onSelect,
    selected: selectedProp = 0,
}) {
    // State
    const [selected, setSelected] = useState(selectedProp);
    const [disabled, setDisabled] = useState(false);
    const [state, setState] = useState({
        urls: [],
    });
    const timeRef = useRef(null);

    // Mutations
    const [importUrls, { loading }] = useFetch({ url: URL });

    // Handle actions
    const handleTabChange = useCallback((index) => setSelected(index), []);
    const handleSyncSelect = useCallback(() => {
        timeRef.current = setTimeout(() => {
            onSelect();
            onClose();
        }, 300);
    }, [onSelect, onClose]);

    const handleImportUrl = useCallback(async () => {
        const urls = state.urls;
        if (urls?.length > 0) {
            try {
                const data = await importUrls({ body: { urls } });
                const success = data?.data?.success;
                if (success?.length > 0) {
                    const result = success
                        .map((item) => getFile(item))
                        .filter(Boolean);

                    setFiles(result);
                    handleSyncSelect();
                }
            } catch (err) {
                console.error(err);
            }
        }
    }, [state, importUrls, setFiles, handleSyncSelect]);

    const handleSelect = useCallback(async () => {
        if (selected === 2) {
            handleImportUrl();
            return;
        }
        handleSyncSelect();
    }, [selected, handleImportUrl, handleSyncSelect]);

    const handleUploadChange = useCallback(
        (value) => {
            setFiles(value);
        },
        [setFiles]
    );

    // Did mount
    useEffect(
        () => () => {
            clearTimeout(timeRef.current);
        },
        []
    );

    const tabs = useMemo(
        () => [
            {
                id: "upload",
                content: "Upload",
                accessibilityLabel: "Upload",
                panelID: "upload",
                component: (
                    <Upload
                        onChange={handleUploadChange}
                        setDisabled={setDisabled}
                        multiple={multiple}
                    />
                ),
            },
            {
                id: "library",
                content: "Library",
                accessibilityLabel: "Library",
                panelID: "library",
                component: (
                    <Library
                        setFiles={setFiles}
                        value={files}
                        multiple={multiple}
                    />
                ),
            },
            {
                id: "import-from-urls",
                content: "Import From URLs",
                accessibilityLabel: "Import From URLs",
                panelID: "import-from-urls",
                component: (
                    <ImportUrls
                        setStateRoot={setState}
                        value={state.urls}
                        multiple={multiple}
                    />
                ),
            },
        ],
        [state, multiple, files, setFiles, setState, handleUploadChange]
    );

    return (
        <Modal
            title="Media Library"
            open={open}
            onClose={onClose}
            large
            primaryAction={{
                content: "Select",
                onAction: handleSelect,
                loading: loading,
                disabled: selected === 0 ? disabled : false,
            }}
            secondaryActions={[{ content: "Cancel", onAction: onClose }]}
        >
            <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                <Card.Section>{tabs[selected].component}</Card.Section>
            </Tabs>
        </Modal>
    );
}
