import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    Button,
    Card,
    Checkbox,
    Collapsible,
    ColorPicker,
    InlineError,
    Labelled,
    Layout,
    Stack,
    TextField,
    TextStyle,
} from "@shopify/polaris";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Layers } from "./Layers";
import { MockupTemplateProvider, useMockupTemplateCtx } from "./context";
import { hsbToRgb, RGBToHex } from "../../utils";

import { Box } from "../CanvasBuilder";
import { useToggle } from "../../hooks";
import { MOCKUP_TYPE } from "../../constants";

export function Form({ url, onSubmit, loading, value, isIFrame }) {
    return (
        <Wrapper>
            <MockupTemplateProvider>
                <Consumer value={value}>
                    <Layout>
                        <Layout.Section secondary>
                            <Card>
                                <div className="card-custom">
                                    <Controls />
                                </div>
                            </Card>
                        </Layout.Section>
                        <Layout.Section>
                            <Card sectioned hideOnPrint>
                                <SecondarySection>
                                    <Box />
                                </SecondarySection>
                            </Card>
                            <ButtonsWrapper
                                url={url}
                                onSubmit={onSubmit}
                                loading={loading}
                                isIFrame={isIFrame}
                            />
                        </Layout.Section>
                    </Layout>
                </Consumer>
            </MockupTemplateProvider>
        </Wrapper>
    );
}

export function Consumer({ value, children, isDesignMockup = false }) {
    const { updateState, updateFiles, percent, toggleFileActive } =
        useMockupTemplateCtx();
    useEffect(() => {
        if (value != null) {
            const {
                name,
                width,
                height,
                default_background_color,
                layers,
                is_change_background_with_variant_color: advanceSettings,
            } = value;

            const state = {
                name,
                width,
                height,
                backgroundColor: default_background_color,
                advanceSettings,
                fileActive: {},
            };

            const newFiles =
                layers?.length > 0
                    ? layers.map((item) => {
                          const { name, file, ...rest } = item;
                          const newItem = {
                              ...rest,
                              fill: "rgb(25 149 177 / 50%)",
                              elName: name,
                              width: (item.width / 100) * percent,
                              height: (item.height / 100) * percent,
                              x: (item.x / 100) * percent,
                              y: (item.y / 100) * percent,
                          };

                          if (rest.type === MOCKUP_TYPE.Background) {
                              newItem.fill = "";
                          } else if (isDesignMockup) {
                              const id = newItem.id;
                              //   toggleFileActive(id);
                          }

                          if (file != null) {
                              newItem.imageUrl = file?.url;
                          }

                          if (
                              isDesignMockup &&
                              rest.type === MOCKUP_TYPE.Design
                          ) {
                              newItem.imageUrl = rest.imageUrl;
                              state.fileActive[rest.id] = true;
                          }

                          return newItem;
                      })
                    : null;

            updateFiles(newFiles);
            updateState(state);
        } else {
            if (isDesignMockup) {
                updateFiles([]);
            }
        }
    }, [
        value,
        percent,
        updateState,
        updateFiles,
        toggleFileActive,
        isDesignMockup,
    ]);

    return children;
}

function SecondarySection({ children }) {
    const { width, height } = useMockupTemplateCtx();

    const dimension = [width, height].filter(Boolean).join("x") + "px";

    return (
        <div className="form-wrap">
            <TextStyle variation="strong">{dimension}</TextStyle>
            {children}
        </div>
    );
}

function Controls() {
    const { name, files, errors, advanceSettings, updateValue } =
        useMockupTemplateCtx();

    // Handle action
    const handleValueChange = useCallback(
        (value, id) => {
            updateValue(value, id);
        },
        [updateValue]
    );

    return (
        <Stack spacing="tight" vertical>
            <TextField
                value={name}
                id="name"
                onChange={handleValueChange}
                placeholder="Template 01"
                label="Template name"
                error={errors?.name}
            />
            <div>
                <Labelled label="Layers" />
                <Layers files={files} />
                {errors?.files && (
                    <InlineError fieldID="error-files" message={errors.files} />
                )}
            </div>
            <div>
                <Labelled label="Advance settings" />
                <Checkbox
                    checked={advanceSettings}
                    id="advanceSettings"
                    onChange={handleValueChange}
                    label="Change background with variant color?"
                />
            </div>
            <BackgroundColor
                advanceSettings={advanceSettings}
                handleValueChange={handleValueChange}
            />
        </Stack>
    );
}

function BackgroundColor({ advanceSettings, handleValueChange }) {
    const { backgroundColor } = useMockupTemplateCtx();

    // State
    const [open, toggleOpen] = useToggle(false);
    const [color, setColor] = useState({
        hue: 0,
        brightness: 100,
        saturation: 0,
    });

    const colorRef = useRef(false);

    useEffect(() => {
        if (color != null && colorRef.current) {
            const res = hsbToRgb(color);
            if (res != null) {
                const { green, red, blue } = res;
                const result = RGBToHex(red, green, blue);
                handleValueChange(result, "backgroundColor");
            }
        }
    }, [handleValueChange, color]);

    return advanceSettings ? (
        <div className="bg-wrap">
            <Labelled label="Default background color" />
            <TextField
                placeholder="#fff"
                id="backgroundColor"
                value={backgroundColor}
                onChange={handleValueChange}
                connectedRight={
                    <div
                        className="box"
                        style={{ backgroundColor }}
                        onClick={toggleOpen}
                    />
                }
            />

            <Collapsible
                id="picker-color"
                open={open}
                transition={{ duration: "150ms", timingFunction: "ease" }}
            >
                <ColorPicker
                    onChange={(...args) => {
                        setColor(...args);
                        colorRef.current = true;
                    }}
                    color={color}
                />
            </Collapsible>
        </div>
    ) : null;
}

function ButtonsWrapper({ url, onSubmit, loading, isIFrame }) {
    // Context
    const {
        name,
        width,
        height,
        backgroundColor,
        files,
        validateField,
        errors,
        percent,
        advanceSettings,
    } = useMockupTemplateCtx();

    const navigate = useNavigate();
    const redirectRef = useRef(null);

    // Handle actions
    const handleRedirect = useCallback(() => {
        redirectRef.current = setTimeout(() => {
            navigate(url);
        }, 100);
    }, [url, navigate]);

    const handleSubmit = useCallback(() => {
        const state = {
            name,
            width,
            height,
            backgroundColor,
            files,
        };

        for (let [key, value] of Object.entries(state)) {
            validateField(value, key);
        }

        const noErr = Object.values(errors).every((i) => i == null);
        if (name && width && height && files?.length && noErr) {
            const layers = files.map((file, index) => {
                const { fill, id, elName, ...rest } = file;
                const item = {
                    ...rest,
                    name: elName,
                    width: (file.width * 100) / percent,
                    height: (file.height * 100) / percent,
                    x: (file.x * 100) / percent,
                    y: (file.y * 100) / percent,
                };
                if (!index) {
                    item.width = width;
                    item.height = height;
                }

                return item;
            });

            const input = {
                name,
                width,
                height,
                defaultBackgroundColor: backgroundColor,
                layers,
                is_change_background_with_variant_color: advanceSettings,
            };

            onSubmit?.(input);
        }
    }, [
        name,
        width,
        height,
        backgroundColor,
        files,
        validateField,
        errors,
        percent,
        onSubmit,
        advanceSettings,
    ]);

    // Did mount
    useEffect(() => () => clearTimeout(redirectRef.current), []);

    const handleReceiveMessage = useCallback(
        (event) => {
            const msg = event?.data;
            if (msg === "Submit") {
                handleSubmit();
            }
        },
        [handleSubmit]
    );

    useEffect(() => {
        window.addEventListener("message", handleReceiveMessage);

        return () =>
            window.removeEventListener("message", handleReceiveMessage, false);
    }, [handleReceiveMessage]);

    return isIFrame ? null : (
        <div className="btn-wrap">
            <Button children="Cancel" onClick={handleRedirect} />
            <Button
                primary
                loading={loading}
                children="Save Changes"
                onClick={handleSubmit}
            />
        </div>
    );
}

const Wrapper = styled.div`
    .Polaris-Layout__Section--secondary {
        flex: 1 1 16rem;
    }

    .card-custom {
        padding: 1rem;
    }

    .form-wrap {
        overflow: auto;

        > span {
            margin-bottom: 0.5rem;
            display: block;
        }
    }

    .btn-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 2rem;
    }

    .bg-wrap {
        .box {
            width: 3.6rem;
            height: 3.6rem;
            border-radius: 5px;
            border: 1px solid var(--p-border-subdued);
            cursor: pointer;
        }

        #picker-color {
            margin-top: 1rem;

            .Polaris-ColorPicker__MainColor {
                width: 14.4rem;
            }

            .Polaris-ColorPicker__HuePicker {
                width: 1.8rem;
            }
        }
    }
`;
