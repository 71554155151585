import React, { Fragment, useCallback, useMemo, useRef } from "react";
import { SettingsMinor, ViewMinor } from "@shopify/polaris-icons";

import { useDesignContext } from "../context";
import { Button, Stack } from "@shopify/polaris";
import { FooterAction } from "./FooterAction";

export function TopAction() {
    // Context
    const { updateState, isEdit: isEditMockup, preview } = useDesignContext();

    // Ref
    const topActionRef = useRef(null);

    // Actions
    const handleEditMockup = useCallback(() => {
        updateState({ isEdit: true });
    }, [updateState]);

    const handlePreview = useCallback(() => {
        updateState({ isEdit: false });

        if (preview) {
            topActionRef.current && topActionRef.current.onGenerate();
        }
    }, [updateState, preview]);

    // Markup
    const btnMarkup = useMemo(() => {
        const label = isEditMockup ? "Save changes & Preview" : "Edit mockup";
        const icon = isEditMockup ? ViewMinor : SettingsMinor;
        const onAction = isEditMockup ? handlePreview : handleEditMockup;

        return <Button children={label} icon={icon} onClick={onAction} />;
    }, [isEditMockup, handlePreview, handleEditMockup]);

    return (
        <Fragment>
            <Stack distribution="trailing">
                {btnMarkup}
                <FooterAction ref={topActionRef} />
            </Stack>
        </Fragment>
    );
}
