import React from "react";
import { Page } from "@shopify/polaris";

import { Edit } from "../components/MockupTemplate/Edit";
import { useAppContext } from "../context";

const url = "/mockup-templates";
export function EditMockupTemplatePage() {
    const { isIFrame } = useAppContext();

    const props = {
        title: "Update Mockup Template",
        breadcrumbs: [{ content: "All Mockup Templates", url }],
    };

    return (
        <Page fullWidth {...(isIFrame ? {} : props)}>
            <div style={isIFrame ? { paddingTop: "1.6rem" } : {}}>
                <Edit url={url} isIFrame={isIFrame} />
            </div>
        </Page>
    );
}
