import { Filters } from "@shopify/polaris";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

export function Filter({ filter, onChange }) {
    // State
    const [inputValue, setInputValue] = useState("");

    const timeoutRef = useRef(null);

    // Handle actions
    useEffect(() => {
        timeoutRef.current = setTimeout(() => {
            onChange?.({
                search: inputValue?.trim() ?? "",
            });
        }, 350);

        return () => clearTimeout(timeoutRef.current);
    }, [inputValue, onChange]);

    const handleInputChange = useCallback((value) => {
        setInputValue(value);
    }, []);

    const handleInputRemove = useCallback(() => setInputValue(""), []);

    const fieldsToClear = useMemo(
        () => [handleInputChange],
        [handleInputChange]
    );

    const handleFilterClearAll = useCallback(() => {
        for (let fn of fieldsToClear) {
            fn?.();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...fieldsToClear]);

    return (
        <Filters
            filters={[]}
            queryValue={inputValue}
            queryPlaceholder="Filter template"
            onQueryChange={handleInputChange}
            onQueryClear={handleInputRemove}
            onClearAll={handleFilterClearAll}
        />
    );
}
