import { ActionList, Button, Popover } from "@shopify/polaris";
import React, { Fragment, useCallback, useMemo, useState } from "react";
import { MOCKUP_TYPE } from "../../constants";
import { useAppContext } from "../../context";
import { useToggle } from "../../hooks";
import { ModalMedia } from "../Media";

export function PrintArea({ addItem }) {
    // Context
    const { designPositions } = useAppContext();
    // State
    const [open, toggleOpen] = useToggle(false);
    const [showModal, toggleShowModal] = useToggle(false);
    const [values, setValues] = useState([]);

    // Handle action
    const handleArea = useCallback(() => {
        addItem({ type: MOCKUP_TYPE.Design });
    }, [addItem]);

    const handleSelect = useCallback(() => {
        const [file] = values;
        if (file != null) {
            addItem({ ...file, type: MOCKUP_TYPE.Design });
        }
    }, [values, addItem]);

    const handleAddDP = useCallback(
        (dp) => {
            toggleOpen(false);
            addItem({ name: dp, type: MOCKUP_TYPE.Design });
        },
        [addItem, toggleOpen]
    );

    const actions = useMemo(() => {
        return designPositions?.length > 0
            ? designPositions.map((dp) => {
                  return {
                      content: dp,
                      onAction: () => handleAddDP(dp),
                  };
              })
            : [];
    }, [handleAddDP, designPositions]);

    const special = useMemo(
        () => designPositions.length > 0,
        [designPositions]
    );

    // Markup
    const activator = (
        <Button
            children="Add Printarea"
            onClick={special ? toggleOpen : handleArea}
            disclosure={!!special}
        />
    );

    return (
        <Fragment>
            {special ? (
                <Popover
                    active={open}
                    onClose={toggleOpen}
                    activator={activator}
                    fullWidth
                >
                    <ActionList items={actions} />
                </Popover>
            ) : (
                activator
            )}
            <ModalMedia
                open={showModal}
                onClose={toggleShowModal}
                files={values}
                setFiles={setValues}
                onSelect={handleSelect}
            />
        </Fragment>
    );
}
