import React, { useCallback, useEffect, useState } from "react";
import { TextField } from "@shopify/polaris";
import styled from "styled-components";

export function ImportUrls({ value, setStateRoot, multiple = false }) {
    // State
    const [urls, setUrls] = useState("");

    // Get data
    useEffect(() => {
        if (value?.length > 0) {
            let newValue = value;

            if (!multiple) {
                newValue = newValue.splice(0, 1);
            }

            newValue = newValue.join("\n");

            setUrls(newValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiple]);

    // Handle actions
    const handleUrlsChange = useCallback(
        (value) => {
            setUrls(value);
            // import urls
            let urls = value
                ?.split(/\n/g)
                .map((i) => i?.trim())
                .filter(Boolean);

            if (!multiple) {
                urls = urls.splice(0, 1);
            }
            setStateRoot((prev) => ({ ...prev, urls }));
        },
        [setStateRoot, multiple]
    );

    return (
        <Wrapper>
            <TextField
                value={urls}
                onChange={handleUrlsChange}
                placeholder="Enter urls"
                helpText={multiple ? "Enter each images url per line" : ""}
                multiline={4}
            />
        </Wrapper>
    );
}

const Wrapper = styled.div`
    min-height: 50rem;
`;
