import { Page } from "@shopify/polaris";
import React, { useCallback, useEffect } from "react";
import { MockupTemplate } from "../../MockupTemplate";
import { STEP, useDesignContext } from "../context";

export function ChooseTemplate() {
    const { templateSelected, updateState } = useDesignContext();

    useEffect(() => {
        updateState({ isChooseTemplate: true });
    }, [updateState]);

    // Handle actions
    const handleContinue = useCallback(() => {
        updateState({ step: STEP.CreateMockup });
    }, [updateState]);

    const disabled = templateSelected?.length === 0;

    return (
        <Page
            title="Choose Templates"
            fullWidth
            primaryAction={{
                content: "Continue",
                disabled: disabled,
                onAction: handleContinue,
            }}
        >
            <MockupTemplate />
        </Page>
    );
}
