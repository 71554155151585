import { JobsMajor, StoreMajor, TemplateMajor } from "@shopify/polaris-icons";

const DASHBOARD = [
    {
        label: "Dashboard",
        url: "/dashboard",
        key: "dashboard",
        icon: StoreMajor,
    },
];

export const ADMINISTRATOR = [
    ...DASHBOARD,
    {
        label: "Mockup Templates",
        url: "/mockup-templates",
        key: "mockup-templates",
        icon: TemplateMajor,
        subNavigationItems: [
            {
                label: "All Mockup Templates",
                url: "/mockup-templates",
                key: "all-mockup-templates",
            },
            {
                label: "Create Mockup Template",
                url: "/mockup-templates/create",
                key: "create-mockup-template",
            },
        ],
    },
    {
        label: "Mockup Jobs",
        url: "/mockup-jobs",
        key: "mockup-jobs",
        icon: JobsMajor,
        subNavigationItems: [
            {
                label: "All Mockup Jobs",
                url: "/mockup-jobs",
                key: "all-mockup-jobs",
            },
        ],
    },
];
