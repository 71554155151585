import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Modal, Spinner } from "@shopify/polaris";
import { CirclePlusMinor } from "@shopify/polaris-icons";
import styled from "styled-components";

import { useToggle } from "../../../hooks";
import { MockupTemplate } from "../../MockupTemplate";
import { useDesignContext } from "../context";
import { elContains } from "../../../utils";
import { IFRAME_MESSAGE_TYPE } from "../../../constants";

export function ChooseOtherMockup({ base, onRemoveTemplate }) {
    const baseId = base?.id;
    // State
    const [open, toggleOpen] = useToggle(false);
    const [templateChoices, setTemplateChoices] = useState([]);

    // Context
    const { updateState, updateLoadingOM, baseLoading } = useDesignContext();

    useEffect(() => {
        updateState({ isChooseTemplate: true });
    }, [updateState]);

    const { taskBaseSubmissions } = base;
    const [sub] = taskBaseSubmissions || [];
    const { id: taskBaseSubmissionId } = sub || {};

    const activeIds = useMemo(() => {
        const { taskBaseSubmissions } = base;
        const [sub] = taskBaseSubmissions || [];
        const { taskBaseMockups } = sub || {};
        const activeIds = (taskBaseMockups || [])
            .map((i) => i.originTemplateId)
            .filter(Boolean)
            // Get unique
            .filter((el, i, arr) => i === arr.lastIndexOf(el));

        return activeIds;
    }, [base]);

    useEffect(() => {
        if (activeIds?.length > 0) {
            setTemplateChoices(activeIds);
        }
    }, [activeIds, open]);

    // Actions
    const handleChoose = useCallback(() => {
        // Remove exists templates.
        const removedTemplate = activeIds.filter(
            (i) => !templateChoices.includes(i)
        );
        if (removedTemplate?.length > 0) {
            onRemoveTemplate?.(removedTemplate);
        }

        const originTemplateIds = (templateChoices || []).filter(
            (i) => !activeIds.includes(i)
        );
        toggleOpen(false);

        // Add new template.
        if (originTemplateIds?.length > 0 && taskBaseSubmissionId != null) {
            const input = {
                originTemplateIds,
                taskBaseSubmissionId,
            };

            const data = {
                type: IFRAME_MESSAGE_TYPE.ChooseOtherTemplate,
                input,
            };

            window?.parent?.postMessage(JSON.stringify(data), "*");
        }

        if (originTemplateIds?.length > 0 || removedTemplate?.length > 0) {
            updateLoadingOM(baseId);
        }
    }, [
        templateChoices,
        activeIds,
        taskBaseSubmissionId,
        toggleOpen,
        onRemoveTemplate,
        baseId,
        updateLoadingOM,
    ]);

    const handleClickChoose = useCallback(
        (baseId) => {
            return () => {
                toggleOpen();
                updateState({ baseChoose: baseId });
            };
        },
        [updateState, toggleOpen]
    );

    useEffect(() => {
        let domCD = document.querySelector("#choose-other-template");
        let domModal = document.querySelectorAll(".Polaris-Modal-Section");
        const domBtnPrimary = document.querySelectorAll(
            ".Polaris-Button--primary"
        );
        let domParents = document.querySelectorAll(
            ".Polaris-Modal-Dialog__Modal"
        );

        for (let domChild of domModal) {
            if (elContains(domChild, domCD)) {
                domChild.setAttribute("style", "padding: 0; height: 100%");
            }
        }

        for (let domParent of domParents) {
            if (elContains(domParent, domCD)) {
                domParent.setAttribute(
                    "style",
                    "max-width: calc(100vw - 2rem); height: 100vh; max-height: calc(100vh - 2rem);"
                );
            }
        }

        for (let domBtn of domBtnPrimary) {
            const domParent = domParents[0];
            if (domParent && elContains(domParent, domBtn)) {
                domBtn.setAttribute(
                    "style",
                    `background: linear-gradient(180deg,#6371c7,#5563c1);
                    border-color: #3f4eae;
                    box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgb(22 29 37 / 5%), 0 0 0 0 transparent;`
                );
            }
        }
    });

    const templateIds = useMemo(() => {
        if (base != null) {
            const { mockupTemplates } = base;
            const ids = (mockupTemplates || [])
                .map((i) => i.originTemplateID)
                .filter(Boolean);

            return ids;
        }
    }, [base]);

    return (
        <Wrapper className="wrapper-choose-other">
            {(baseLoading || []).includes(baseId) ? (
                <Spinner />
            ) : (
                <Button
                    children="Choose other template"
                    size="slim"
                    onClick={handleClickChoose(baseId)}
                    icon={CirclePlusMinor}
                />
            )}
            <Modal
                open={open}
                onClose={toggleOpen}
                title="Choose other template"
                sectioned
                secondaryActions={[{ content: "Cancel", onAction: toggleOpen }]}
                primaryAction={{ content: "Choose", onAction: handleChoose }}
            >
                <ModalWrapper id="choose-other-template">
                    <MockupTemplate
                        includesTemplateIds={templateIds}
                        setTemplateChoices={setTemplateChoices}
                        templateChoices={templateChoices}
                    />
                </ModalWrapper>
            </Modal>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    width: 16rem;
    height: 16rem;
    border: 0.2rem dashed #8c9196;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    display: flex;

    .Polaris-Button {
        width: 100%;
        height: 100%;
        border: none;

        &__Content {
            flex-direction: column;
            row-gap: 0.5rem;
        }

        &__Text {
            font-size: 1.6rem;
        }
    }
`;

const ModalWrapper = styled.div`
    > .Polaris-Card {
        box-shadow: none;
    }
`;
