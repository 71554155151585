import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "./context";

export function RenderRouter(route) {
    const RenderRouter = () => {
        const titleProp = route.title;

        // Context
        const { user } = useAppContext();
        const navigate = useNavigate();

        useEffect(() => {
            let title = titleProp ? `${titleProp} - ` : "";

            document.title = title + "2D Mockup";
        }, [titleProp]);

        useEffect(() => {
            if (!user) {
                navigate("/login");
            }
        }, [user, navigate]);

        const Comp = route.component;
        return <Comp />;
    };

    return RenderRouter;
}
