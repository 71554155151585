import React, { useState, useCallback, useEffect } from "react";
import {
    Page,
    TextField,
    Card,
    Button,
    Form,
    FormLayout,
    Icon,
} from "@shopify/polaris";
import {
    CustomersMinor,
    LockMinor,
    HideMinor,
    ViewMinor,
} from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { MbApi } from "../config";
import { elContains, isEmail, hasError } from "../utils";
import { useToastContext } from "../components/Toast";
import { useAppContext } from "../context";

import { Labelled } from "../components/Labelled";
import { useFetch } from "../hooks";

const Container = styled.div`
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
        width: 100%;
        max-width: 640px;

        @media (min-width: 768px) {
            max-width: 768px;
        }
    }

    .icon-wrap {
        .Polaris-Button {
            margin-top: -1px;
        }
    }

    .heading-wrap {
        h3 {
            font-size: 2.25rem;
            padding-top: 1rem;
            text-align: center;
            font-weight: 500;
        }
    }

    .action-wrap {
        margin: 3rem 0 1rem;
    }

    .suffix-wrap {
        width: 2rem;
    }

    .Polaris-Connected {
        .Polaris-Connected__Item {
            &:not(.Polaris-Connected__Item--primary) {
                z-index: 20;
                position: absolute;
                right: 8px;
                height: 34px;
                line-height: 34px;

                svg {
                    fill: var(--p-icon);
                }
            }
        }
    }
`;

const URL = `${MbApi}/query`;

export function Login() {
    // State
    const [fields, setFields] = useState({
        email: null,
        password: null,
    });
    const [errors, setErrors] = useState({});
    const [iconView, setIconView] = useState(false);

    // Context
    const { user, login: loginUser } = useAppContext();
    const { toggleToast, setNotify } = useToastContext();
    const navigate = useNavigate();

    // Mutation
    const [login, { loading }] = useFetch({ url: URL });

    // Handle action
    const validateField = useCallback((value, id) => {
        let error = null;
        let label = id.charAt(0).toUpperCase() + id.slice(1);

        if (typeof value === "string" && (!value || !value.length)) {
            error = `${label} is required.`;
        } else {
            if (["email"].includes(id)) {
                if (!isEmail(value)) {
                    error = "Email must be a valid email address.";
                } else {
                    error = null;
                }
            }
        }
        setErrors((prev) => ({ ...prev, [id]: error }));
    }, []);

    const handleInputChange = useCallback(
        (value, id) => {
            setFields((prev) => ({ ...prev, [id]: value }));
            validateField(value, id);
        },
        [validateField]
    );

    const handleSubmit = useCallback(async () => {
        for (let item of ["email", "password"]) {
            validateField(fields[item], item);
        }

        const hasErr = hasError(errors);

        const { email, password } = fields;

        // Required all fields
        const hasValues = Object.values(fields).every((i) => i);

        if (!hasErr && hasValues) {
            toggleToast?.(true);
            setNotify({ msg: null, err: false });

            try {
                const body = {
                    query: `
                    mutation login($email: String!, $password: String!, $remember: Boolean) {
                        login(email: $email, password: $password, remember: $remember) {
                            id
                            expiredAt
                            user {
                                id
                                firstName
                                lastName
                                email
                            }
                        }
                    }
                `,
                    variables: {
                        email,
                        password,
                        remember: true,
                    },
                };
                const data = await login({ body });
                const res = data?.data?.login;
                if (res != null) {
                    setNotify({ msg: "Login successfully.", err: false });
                    loginUser(res);
                }

                const error = data?.error;
                if (error?.length > 0) {
                    const { message } = error[0];
                    setNotify({ msg: message?.toString(), err: true });
                }
            } catch (err) {
                setNotify({ msg: err?.toString(), err: true });
            }
        }
    }, [
        login,
        validateField,
        setNotify,
        toggleToast,
        errors,
        fields,
        loginUser,
    ]);

    useEffect(() => {
        if (user != null) {
            navigate("/dashboard");
        }
    }, [user, navigate]);

    // Markup
    const iconViewMarkup = (
        <div className="icon-wrap">
            <Button plain onClick={() => setIconView((iconView) => !iconView)}>
                <Icon source={!iconView ? HideMinor : ViewMinor} />
            </Button>
        </div>
    );

    useEffect(() => {
        let domIcons = document.querySelectorAll(".icon-wrap");
        let listBtn = document.querySelectorAll(".Polaris-Button");

        for (let domIcon of domIcons) {
            for (let domBtn of listBtn) {
                if (elContains(domIcon, domBtn)) {
                    domBtn.setAttribute("tabindex", "-1");
                }
            }
        }
    });

    return (
        <Container>
            <div className="container">
                <Page>
                    <Card sectioned>
                        <Form onSubmit={handleSubmit}>
                            <FormLayout>
                                <div className="heading-wrap">
                                    <h3>Welcome back!</h3>
                                </div>
                                <div>
                                    <Labelled label="Email" required />
                                    <TextField
                                        id="email"
                                        type="email"
                                        placeholder="Email"
                                        value={fields["email"]}
                                        error={errors["email"]}
                                        onChange={handleInputChange}
                                        prefix={
                                            <Icon source={CustomersMinor} />
                                        }
                                    />
                                </div>
                                <div>
                                    <Labelled label="Password" required />
                                    <TextField
                                        id="password"
                                        value={fields["password"]}
                                        onChange={handleInputChange}
                                        error={errors["password"]}
                                        type={!iconView ? "password" : "text"}
                                        placeholder="***************"
                                        prefix={<Icon source={LockMinor} />}
                                        suffix={<div className="suffix-wrap" />}
                                        connectedRight={iconViewMarkup}
                                    />
                                </div>
                                <div className="action-wrap">
                                    <Button
                                        fullWidth
                                        primary
                                        submit
                                        children="Login"
                                        loading={loading}
                                    />
                                </div>
                            </FormLayout>
                        </Form>
                    </Card>
                </Page>
            </div>
        </Container>
    );
}
