import {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useReducer,
} from "react";
import { match } from "../../../utils";

import data from "../mock.json";

const GenerateMockupContext = createContext(null);
GenerateMockupContext.displayName = "GenerateMockupContext";

const ActionType = {
    UpdateState: "UpdateState",
};

const reducers = {
    [ActionType.UpdateState](state, action) {
        return {
            ...state,
            ...action.payload,
        };
    },
};

function stateReducer(state, action) {
    return match(action.type, reducers, state, action);
}

const initialValue = {
    templateSelected: data || [],
    templateActive: null,
    mockupActive: null,
};

export function GenerateMockupProvider({ children }) {
    const [state, dispatch] = useReducer(stateReducer, initialValue);

    // Actions
    const updateState = useCallback(
        (newState) => {
            dispatch({
                type: ActionType.UpdateState,
                payload: newState,
            });
        },
        [dispatch]
    );

    const bag = useMemo(() => {
        return {
            ...state,
            updateState,
        };
    }, [state, updateState]);

    return (
        <GenerateMockupContext.Provider value={bag}>
            {children}
        </GenerateMockupContext.Provider>
    );
}

export function useGenerateMockup() {
    return useContext(GenerateMockupContext);
}
