import React, { useCallback, useEffect, useRef, useState } from "react";
import {
    TextField,
    Icon,
    Scrollable,
    Checkbox,
    Spinner,
} from "@shopify/polaris";
import { SearchMajor } from "@shopify/polaris-icons";
import styled from "styled-components";

import { API_DOMAIN } from "../../config";
import { useFetch } from "../../hooks";
import { ImageTypes, getUniqueBy } from "../../utils";

import { EmptyStateComp } from "../Shared/EmptyState";
import { FileDetail } from "./FileDetail";

const URL = `${API_DOMAIN}/file`;

export function Library({ multiple, value, setFiles }) {
    // State
    const [inputValue, setInputValue] = useState("");
    const [images, setImages] = useState([]);
    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState({
        limit: 10,
        page: 1,
        search: "",
    });

    const timeRef = useRef(null);
    // Query
    const [getImages, { loading }] = useFetch({ url: URL, method: "get" });

    // Get data
    useEffect(() => {
        try {
            async function fetchData() {
                const data = await getImages({ params: filter });
                setTotal(data?.data?.total || 0);
                let newData =
                    data?.data?.nodes?.length > 0
                        ? data.data.nodes.map((node) => {
                              return {
                                  id: node.id,
                                  name: node.name,
                                  mimeType: node.mime_type,
                                  url: node.url,
                                  thumbnailUrl: node.thumbnail_url,
                                  size: node.size,
                              };
                          })
                        : [];
                setImages((prev) => {
                    const newImages = getUniqueBy([...prev, ...newData], "id");
                    return newImages;
                });
            }
            fetchData();
        } catch (err) {}
    }, [filter, getImages]);

    // Handle actions
    const handleInputChange = useCallback((value) => {
        setInputValue(value);

        timeRef.current = setTimeout(() => {
            setFilter((prev) => ({ ...prev, search: value }));
        }, 350);
    }, []);

    const handleScroll = useCallback(() => {
        if (!loading) {
            setFilter((prev) => {
                const maxPage = Math.ceil(total / prev.limit);
                const newPage = prev.page + 1;

                if (newPage <= maxPage) {
                    return {
                        ...prev,
                        page: newPage,
                    };
                }
                return prev;
            });
        }
    }, [loading, total]);

    const isImage = useCallback(
        (value) => ImageTypes.includes(value.mimeType),
        []
    );
    const handleClick = useCallback(
        (img) => {
            let index = value.findIndex((i) => i.id === img.id);

            let newValue;
            if (index !== -1) {
                newValue = value.filter((i) => i.id !== img.id);
            } else {
                if (multiple) {
                    newValue = [...value, img];
                } else {
                    newValue = [img];
                }
            }

            setFiles(newValue);
        },
        [value, multiple, setFiles]
    );

    // Did mount
    useEffect(() => () => clearTimeout(timeRef.current), []);

    let len = value.length;

    return (
        <Wrapper>
            <div className="media-library-wrap">
                <div className="filter-control-wrap">
                    <div className="search-wrap">
                        <TextField
                            value={inputValue}
                            onChange={handleInputChange}
                            placeholder="Filter image"
                            prefix={
                                <Icon source={SearchMajor} color="inkLighter" />
                            }
                            onClearButtonClick={() => handleInputChange(null)}
                            clearButton
                        />
                    </div>
                </div>
                <Scrollable
                    shadow
                    style={{ height: "70rem" }}
                    onScrolledToBottom={handleScroll}
                >
                    <div
                        id="media-library"
                        className="media-library-content-wrap"
                    >
                        {images && images.length > 0 ? (
                            images.map((image, index) => (
                                <div
                                    key={`${index} - ${image.id}`}
                                    className="image"
                                    title={image.name}
                                    onClick={() => handleClick(image)}
                                >
                                    <div
                                        className="check"
                                        style={{
                                            visibility: value.find(
                                                (v) => v.id === image.id
                                            )
                                                ? "visible"
                                                : "hidden",
                                        }}
                                    >
                                        <Checkbox label="" checked />
                                    </div>
                                    {isImage(image) ? (
                                        <div
                                            className="image-inner"
                                            style={{
                                                backgroundImage: `url(${
                                                    image.thumbnailUrl
                                                        ? image.thumbnailUrl
                                                        : image.url
                                                        ? image.url
                                                        : null
                                                })`,
                                            }}
                                        />
                                    ) : (
                                        <div className="picture-outlined">
                                            <span>
                                                {image && image.name
                                                    ? image.name
                                                          .split(".")
                                                          .pop()
                                                    : null}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            <EmptyStateComp noData />
                        )}
                    </div>
                    {loading && (
                        <div
                            style={{
                                padding: "4rem 0 2rem",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Spinner size="large" hasFocusableParent />
                        </div>
                    )}
                </Scrollable>
            </div>
            <div className="file-detail-wrap">
                {len > 0 ? (
                    <div className="inner">
                        {value.length > 0 && (
                            <FileDetail
                                value={value[len - 1]}
                                isImage={isImage(value[len - 1])}
                                setImages={setImages}
                                setFiles={setFiles}
                            />
                        )}
                    </div>
                ) : null}
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    margin: -2rem;
    display: flex;
    width: 100%;
    min-height: 30rem;

    .media-library-wrap {
        width: 76%;
        border-right: var(
            --p-thin-border-subdued,
            0.1rem solid var(--p-border-subdued, #dfe3e8)
        );
    }

    .filter-control-wrap {
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
        padding: 1.6rem;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;

        .search-wrap {
            width: 60%;
        }

        .more-filter-wrap {
            display: flex;
            flex-direction: row;
            column-gap: 1rem;
        }
    }

    .media-library-content-wrap {
        display: flex;
        flex-wrap: wrap;
        gap: 3rem 2rem;
        width: 100%;
        padding: 1rem 1.6rem 1.6rem;
        justify-content: start;

        .image {
            width: 160px;
            height: 160px;
            cursor: pointer;
            border-radius: 3px;
            position: relative;
            box-shadow: 0 -1px 15px -3px rgba(0, 0, 0, 0.1),
                0 4px 6px 2px rgba(0, 0, 0, 0.1);

            .image-inner {
                width: 100%;
                height: 100%;
                background-size: cover;
                align-items: center;
                justify-content: center;
                display: flex;
            }

            .check {
                position: absolute;
                top: 0;
                right: 0;

                .Polaris-Choice {
                    padding: 0;

                    .Polaris-Choice__Control {
                        margin: 0;
                        padding: 0;
                        width: 2rem;
                        height: 2rem;
                    }
                }
            }
        }
        .picture-outlined {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
                font-size: 20px;
            }
        }
    }

    .file-detail-wrap {
        width: 24%;
    }

    .loading-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 3rem;

        .Polaris-Spinner--sizeLarge svg {
            height: 6rem;
            width: 6rem;
        }
    }
`;
