import { match } from "../utils";
import { ActionTypes } from "./actions";

const reducers = {
    [ActionTypes.ChooseFile](state, action) {
        return {
            ...state,
            file: action.file,
        };
    },
    [ActionTypes.Login](state, action) {
        return {
            ...state,
            user: action.user,
            token: action.token,
        };
    },
    [ActionTypes.Logout](state) {
        return {
            ...state,
            user: null,
            token: null,
        };
    },
    [ActionTypes.CheckIFrame](state, action) {
        if (state.isIFrame === action.payload) return state;
        return {
            ...state,
            isIFrame: action.payload,
        };
    },
    [ActionTypes.UpdateDesignPosition](state, action) {
        return {
            ...state,
            designPositions: action.payload,
        };
    },
    [ActionTypes.UpdateTemplateSelected](state, action) {
        return {
            ...state,
            templateSelected: action.payload,
        };
    },
};

export function stateReducer(state, action) {
    return match(action.type, reducers, state, action);
}
