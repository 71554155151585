import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from "react";

import { ChooseTemplate } from "./ChooseTemplate";
import { STEP, DesignContextProvider, useDesignContext } from "../context";
import { CreateMockup } from "./CreateMockup";
import { useAppContext } from "../../../context";
import { IFRAME_MESSAGE_TYPE } from "../../../constants";

export function Design() {
    const { isIFrame } = useAppContext();

    // Provider value
    const [cloneBases, setCloneBases] = useState([]);

    return (
        <DesignContextProvider
            setCloneBases={setCloneBases}
            cloneBases={cloneBases}
        >
            <DesignInner isIFrame={isIFrame} />
        </DesignContextProvider>
    );
}

function DesignInner({ isIFrame }) {
    const { step, updateState } = useDesignContext();
    useEffect(() => {
        if (isIFrame) {
            updateState({ step: STEP.CreateMockup });
        }
    }, [isIFrame, updateState]);

    // Get data from iframe MB.
    const handleReceiveMessage = useCallback(
        (event) => {
            const data = event?.data;
            if (data !== null && typeof data === "string") {
                const value = JSON.parse(window.atob(data));
                const { type, message } = value || {};
                const isSendReview = type === IFRAME_MESSAGE_TYPE.SendReview;
                if (!isSendReview) return;

                const templateSelected = JSON.parse(window.atob(message));
                if (templateSelected?.length > 0) {
                    const [templateActive] = templateSelected;
                    const activate = templateActive?.id
                        ? [templateActive.id]
                        : [];

                    updateState({
                        templateSelected,
                        templateActive,
                        activate,
                    });
                }
            }
        },
        [updateState]
    );

    useEffect(() => {
        window.addEventListener("message", handleReceiveMessage);

        return () => {
            window.removeEventListener("message", handleReceiveMessage, false);
        };
    }, [handleReceiveMessage]);

    useLayoutEffect(() => {
        const data = {
            type: IFRAME_MESSAGE_TYPE.OpenSuccess,
            open: true,
        };
        window?.parent?.postMessage(JSON.stringify(data), "*");
    }, []);

    const contentMarkup = useMemo(() => {
        return {
            [STEP.ChooseTemplate]: <ChooseTemplate chooseTemplate />,
            [STEP.CreateMockup]: <CreateMockup />,
        };
    }, []);

    return contentMarkup[step];
}
