import React, { useEffect, useRef } from "react";
import { Page } from "@shopify/polaris";
import styled from "styled-components";

import { Toolbar } from "./Toolbar";
import { MockupDesign } from "./MockupDesign";
// import { BaseComp } from "./BaseComp";
import { GeneratedMockups } from "./GeneratedMockups";

export function CreateMockup() {
    const ref = useRef(null);

    useEffect(() => {
        const handler = () => {
            if (!ref.current) return;
        };

        window.addEventListener("resize", handler);

        return () => {
            window.removeEventListener("resize", handler, false);
        };
    }, []);

    return (
        <div ref={ref}>
            <Page title="Products/Campaigns" fullWidth>
                <Wrapper>
                    <div className="toolbar-wrap">
                        <Toolbar />
                    </div>
                    <div className="mockup-design-wrap">
                        <MockupDesign />
                        {/* <BaseComp /> */}
                        <GeneratedMockups />
                    </div>
                </Wrapper>
            </Page>
        </div>
    );
}

const Wrapper = styled.div`
    --dimension: 15rem;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 2rem;

    .toolbar-wrap {
        flex: 1 1 15%;
        max-width: 30rem;
    }

    .mockup-design-wrap {
        flex: 1 1;
    }
`;
