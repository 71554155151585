import { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";

export function useFetch({ url, method = "post" }) {
    // State
    const [loading, setLoading] = useState(null);

    // Handle actions
    const source = useMemo(() => axios.CancelToken.source(), []);

    const handleMutation = useCallback(
        ({ body, params = {}, headers, timeout }) => {
            return new Promise((resolve, reject) => {
                setLoading(true);

                axios({
                    method,
                    url,
                    data: body,
                    params,
                    headers: {
                        "Content-Type": "application/json",
                        ...headers,
                    },
                    cancelToken: source.token,
                    timeout,
                })
                    .then((res) => {
                        setLoading(false);

                        const data = res.data;
                        resolve({
                            data: data.data,
                            error: data.errors || data.error,
                        });
                    })
                    .catch((err) => {
                        setLoading(false);
                        reject(err);
                    });
            });
        },
        [url, method, source]
    );

    useEffect(
        () => () => {
            source.cancel();
        },
        [source]
    );

    return [handleMutation, { loading }];
}
