import {
    createContext,
    useCallback,
    useContext,
    useReducer,
    useRef,
    useMemo,
    useEffect,
} from "react";
import { match } from "../../../utils";
import { MOCKUP_TYPE } from '../../../constants';

export const ActionTypes = {
    UpdateState: "UpdateState",
    UpdateFile: "UpdateFile",
    UpdateError: "UpdateError",
    FileActive: "FileActive",
};

const reducers = {
    [ActionTypes.UpdateState](state, action) {
        return {
            ...state,
            ...action.payload,
        };
    },
    [ActionTypes.UpdateFile](state, action) {
        return {
            ...state,
            files: action.payload,
        };
    },
    [ActionTypes.UpdateError](state, action) {
        return {
            ...state,
            errors: {
                ...state.errors,
                ...action.payload,
            },
        };
    },
    [ActionTypes.FileActive](state, action) {
        const activeCurrent = !state.fileActive[action.payload];
        const fileInAction = Object.entries(state.fileActive).reduce(
            (acc, [key]) => {
                return {
                    ...acc,
                    [key]: false,
                };
            },
            {}
        );

        return {
            ...state,
            fileActive: {
                ...fileInAction,
                [action.payload]: activeCurrent,
            },
        };
    },
};

function stateReducer(state, action) {
    return match(action.type, reducers, state, action);
}

const MockupTemplateContext = createContext(null);
MockupTemplateContext.displayName = "MockupTemplateContext";

const initialValue = {
    name: "",
    width: 1000,
    height: 920,
    relativeWidth: 1000,
    relativeHeight: 920,
    percent: 100,
    files: [],
    fileActive: {},
    advanceSettings: false,
    backgroundColor: "#fff",
    errors: {},
};

export function MockupTemplateProvider({ children }) {
    const [state, dispatch] = useReducer(stateReducer, initialValue);
    const id = useRef(0);

    const FIELD_REQUIRED = useMemo(
        () => ({
            name: "Template name is required",
            width: "Width is required",
            height: "Height is required",
            files: "Please select at least one layer.",
        }),
        []
    );

    // Hand actions
    useEffect(() => {
        id.current = state.files?.length;
    }, [state.files]);

    const updateError = useCallback(
        (error) => {
            dispatch({ type: ActionTypes.UpdateError, payload: error });
        },
        [dispatch]
    );
    const validateField = useCallback(
        (value, id) => {
            let err = null;
            let label = FIELD_REQUIRED[id];
            if ((!value || value.length === 0) && label) {
                err = label;
            }

            updateError({ [id]: err });
        },
        [FIELD_REQUIRED, updateError]
    );

    const updateValue = useCallback(
        (value, id) => {
            validateField(value, id);
            dispatch({
                type: ActionTypes.UpdateState,
                payload: { [id]: value },
            });
        },
        [dispatch, validateField]
    );

    const updateState = useCallback(
        (newState) => {
            dispatch({
                type: ActionTypes.UpdateState,
                payload: newState,
            });
        },
        [dispatch]
    );

    const addItem = useCallback(
        (file, width, height) => {
            const files = state.files;
            const relativeWidth = state.relativeWidth;
            const relativeHeight = state.relativeHeight;
            const percent = state.percent;
            const total = id.current;

            const newWidth = (200 / 100) * percent;
            const newHeight = (200 / 100) * percent;

            const x = width ? 0 : (relativeWidth - newWidth) / 2; //lastItem?.x + 10 || 0;
            // const x = width ? 0 : (state.width - 200) / 2; //lastItem?.x + 10 || 0;
            // const y = height ? 0 : (state.height - 200) / 2; //lastItem?.y + 10 || 0;
            const y = height ? 0 : (relativeHeight - newHeight) / 2; //lastItem?.y + 10 || 0;
            const nextIndex = total + 1;

            const newElement = {
                x,
                y,
                width: width ?? newWidth,
                height: height ?? newHeight,
                fill: file?.url ? "" : "rgb(25 149 177 / 50%)",
                id: `rect${nextIndex}`,
                rotation: 0,
                elName: file?.name ?? `Layer ${nextIndex}`,
                type: file?.type ?? MOCKUP_TYPE.Design,
                imageUrl: file?.url ?? undefined,
                fileId: file?.id ?? undefined,
            };

            id.current += 1;
            const newFiles = [...files, newElement];
            validateField(newFiles, "files");
            dispatch({ type: ActionTypes.UpdateFile, payload: newFiles });
        },
        [state, dispatch, validateField]
    );

    const updateFiles = useCallback(
        (newFiles) => {
            validateField(newFiles, "files");
            dispatch({ type: ActionTypes.UpdateFile, payload: newFiles });
        },
        [dispatch, validateField]
    );

    const toggleFileActive = useCallback(
        (id) => {
            dispatch({ type: ActionTypes.FileActive, payload: id });
        },
        [dispatch]
    );

    const removeItem = useCallback(
        (idToRemove) => {
            const newFiles = state.files.filter((i) => i.id !== idToRemove);

            validateField(newFiles, "files");
            dispatch({ type: ActionTypes.UpdateFile, payload: newFiles });
            toggleFileActive(idToRemove);
        },
        [state.files, dispatch, validateField, toggleFileActive]
    );

    return (
        <MockupTemplateContext.Provider
            value={{
                ...state,
                dispatch,
                updateState,
                updateValue,
                addItem,
                updateFiles,
                removeItem,
                updateError,
                validateField,
                toggleFileActive,
            }}
        >
            {children}
        </MockupTemplateContext.Provider>
    );
}

export function useMockupTemplateCtx() {
    return useContext(MockupTemplateContext);
}
