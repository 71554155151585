import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Group, Image, Rect, Stage, Layer } from "react-konva";
import useImage from "use-image";

import { Transformer } from "../CanvasBuilder";
export function KonvaComp() {
    return (
        <div
            id="canvas-box"
            style={{
                width: "100%",
                height: "100%",
                backgroundColor: "#f0f0f0",
                margin: "0 auto",
            }}
        >
            <Canvas />
        </div>
    );
}

export function Canvas() {
    const stageRef = useRef(null);
    const [demission, setDemission] = useState({
        width: 0,
        height: 0,
    });

    useEffect(() => {
        function handleResize() {
            const container = document.querySelector("#canvas-box");
            if (!container) return;
            setDemission(() => ({
                width: container.offsetWidth,
                height: container.offsetHeight,
            }));
        }
        handleResize();
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize, false);
    }, []);

    return (
        <Stage ref={stageRef} width={demission.width} height={demission.height}>
            <Layer>
                <GroupComp />
            </Layer>
        </Stage>
    );
}

const imageUrl =
    "https://2dmockups.sgp1.digitaloceanspaces.com/mockups/er2D7ep3ySufBLbzPvLJjB_england.png?AWSAccessKeyId=XAP3PIWYLCNYZZTDHNJ2&Expires=1642476681&Signature=Sy2GXop0bMPt2eV43aCmYbaVoT4%3D";

export function GroupComp() {
    // Ref
    const groupRef = useRef(null);
    const imgRef = useRef(null);

    // Props
    const props = useMemo(
        () => ({
            x: 20,
            y: 20,
            width: 500,
            height: 500,
        }),
        []
    );
    const [image] = useImage(imageUrl);

    // Handle actions
    const handleDragMove = useCallback(() => {
        const group = groupRef.current;
        const image = imgRef.current;
        const imgX = image.x();
        const imgY = image.y();

        const pos = group.absolutePosition();
        pos.width = pos.x + group.width();
        pos.height = pos.y + group.height();

        // Max width || max height
        const maxX = pos.width - image.width() - props.x;
        const maxY = pos.height - image.height() - props.y;

        // If `x` less than 0 or greater than `maxX` => re-set `x`
        if (imgX < 0) {
            image.x(Math.round(Math.max(0, imgX)));
        } else if (maxX <= imgX) {
            image.x(Math.round(maxX));
        }

        // If `y` less than 0 or greater than `maxY` => re-set `y`
        if (imgY < 0) {
            image.y(Math.round(Math.max(0, imgY)));
        } else if (maxY <= imgY) {
            image.y(Math.round(maxY));
        }
    }, [props]);

    const handleDragMoveEnd = useCallback(() => {
        // console.log("imageRef.current  handleDragMoveEnd: ", imgRef.current);
    }, []);

    const handleShapeChange = useCallback(() => {
        const node = imgRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        const rotation = node.rotation();

        // We will rest it back
        node.scaleX(1);
        node.scaleY(1);

        let width = Math.max(5, node.width() * scaleX);
        let height = Math.max(5, node.height() * scaleY);

        const group = groupRef.current;
        let x = Math.round(Math.max(0, node.x()));
        let y = Math.round(Math.max(0, node.y()));

        width = Math.min(width, group.width() - x);
        height = Math.min(height, group.height() - y);

        node.x(x);
        node.y(y);
        node.width(width);
        node.height(height);
        node.rotation(rotation);
    }, []);
    const handleShapeChangeEnd = useCallback(() => {
    }, []);

    return (
        <Group ref={groupRef} {...props}>
            <Rect fill="green" {...props} opacity={0.4} x={0} y={0} />
            <Image
                ref={imgRef}
                image={image}
                width={180}
                height={180}
                x={20}
                y={20}
                onDragMove={handleDragMove}
                onDragEnd={handleDragMoveEnd}
                id="image1"
                // stroke={'red'}
                // strokeWidth={2}
                onTransformEnd={handleShapeChangeEnd}
                onTransform={handleShapeChange}
            />
            <Transformer selectedLayers={["image1"]} />
        </Group>
    );
}
