export const ROLES = {
    Administrator: "Administrator",
};

export const TEAM_ROLE = {
    Designer: "Designer",
};

export const MOCKUP_TYPE = {
    Background: "Background",
    Design: "Design",
};

export const IFRAME_MESSAGE_TYPE = {
    Regenerate: "Regenerate",
    Subscriptions: "Subscriptions",
    SendReview: "SendReview",
    GenerateMockup: "GenerateMockup",
    CreateCustomMockup: "CreateCustomMockup",
    CreateMockupForSubmission: "CreateMockupForSubmission",
    OpenSuccess: "OpenSuccess",
    ChangedLayer: "ChangedLayer",
    DeleteMockups: "DeleteMockups",
    ChooseOtherTemplate: "ChooseOtherTemplate",
    BulkDeleteMockups: "BulkDeleteMockups",
    GetMainMockupId: "GetMainMockupId",
};

export const Key = {
    LeftArrow: 37,
    UpArrow: 38,
    RightArrow: 39,
    DownArrow: 40,
};
