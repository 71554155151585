import { Button, Icon } from "@shopify/polaris";
import {
    DeleteMajor,
    DragDropMajor,
    ImageMajor,
    MaximizeMinor,
    SortMinor,
} from "@shopify/polaris-icons";
import { SortableHandle } from "react-sortable-hoc";
import React, { useCallback, useRef } from "react";
import { placeCaretAtEnd } from "../../utils";

export function Layer({
    item,
    onRemove,
    onChange,
    onActiveItem,
    isActive,
    onExpanded,
}) {
    const isImage = item?.type === "Background";
    const itemRef = useRef(null);

    const handleChange = useCallback(
        (value) => {
            onChange(value);
            placeCaretAtEnd(itemRef.current);
        },
        [onChange]
    );

    return (
        <div className={`item ${isActive && "active"}`}>
            <div className="group-control" onClick={onActiveItem}>
                <span>
                    <Icon source={isImage ? ImageMajor : DragDropMajor} />
                </span>
                <span
                    ref={itemRef}
                    className="name"
                    title={item.elName}
                    contentEditable
                    onInput={handleChange}
                    suppressContentEditableWarning={true}
                >
                    {item.elName}
                </span>
            </div>
            <div className="action-layer">
                <DragHandle />
                <Button plain icon={MaximizeMinor} onClick={onExpanded} />
                <Button plain icon={DeleteMajor} onClick={onRemove} />
            </div>
        </div>
    );
}

const DragHandle = SortableHandle(() => <Button plain icon={SortMinor} />);
