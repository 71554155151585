import React, {
    Fragment,
    useCallback,
    useMemo,
    useState,
    useImperativeHandle,
    forwardRef,
} from "react";
import { Button, Popover, Stack, TextContainer } from "@shopify/polaris";
import { ReplaceMajor } from "@shopify/polaris-icons"; //UploadMajor
import styled from "styled-components";

import { useFetch, useToggle } from "../../../hooks";
import { ModalMedia } from "../../Media";
import { useDesignContext } from "../context";
import { API_DOMAIN } from "../../../config";
import { useMockupTemplateCtx } from "../../MockupTemplate/context";
import { isTypeDesign } from "../../../utils";
import { IFRAME_MESSAGE_TYPE } from "../../../constants";
import { MOCKUP_TYPE } from "../../../constants";
import { useAppContext } from "../../../context";

export function checkType(item) {
    return isTypeDesign(item.type);
}

const URL = `${API_DOMAIN}/2d_mockup/gen_mockup/live_preview`;

export const FooterAction = forwardRef(function FooterAction(_, ref) {
    // Context
    const {
        // isEdit: isEditMockup,
        templateSelected,
        updateTemplateSelected,
        mockupActive,
        templateActive,
        updateState,
        loading,
        preview,
    } = useDesignContext();
    const context = useMockupTemplateCtx();
    const {
        toggleFileActive,
        percent,
        files: filesCtx,
        updateFiles,
    } = context || {};

    const { isIFrame } = useAppContext();

    // State
    const [open, toggleOpen] = useToggle(false);
    const [files, setFiles] = useState([]);
    const [active, toggleActive] = useToggle(false);

    // Mutation
    const [livePreview] = useFetch({
        url: URL,
        method: "post",
    });

    // Handle actions
    const handleUpdateTemplate = useCallback(
        (templateSelected, success) => {
            let cloneTemplates = [...templateSelected];
            for (let i = 0; i < success.length; i++) {
                const item = success[i];
                const input = item?.templateInput;
                const templateId = input?.templateId;
                const layers = input.layers || [];

                cloneTemplates = cloneTemplates.map((i) => {
                    const id = i.id;
                    if (id === templateId) {
                        const newLayers = i.layers.map((i) => {
                            const current = layers.find(
                                (l) => l.name === i.name
                            );
                            if (current != null) {
                                checkType(i) && toggleFileActive(id);

                                return {
                                    ...i,
                                    ...current,
                                };
                            }

                            return i;
                        });

                        return {
                            ...i,
                            dataImage: item.file,
                            layers: newLayers,
                        };
                    }

                    return i;
                });
            }

            updateTemplateSelected(cloneTemplates, true);
        },
        [updateTemplateSelected, toggleFileActive]
    );

    const handleLivePreview = useCallback(
        (imageUrl) => {
            if (templateSelected?.length > 0) {
                const templates = templateSelected.map((tmp) => {
                    const backgroundColor = tmp.default_background_color;
                    const templateId = tmp.id;
                    const layers =
                        tmp.layers?.length > 0
                            ? tmp.layers.filter(checkType).map((layer) => {
                                  return {
                                      name: layer.name,
                                      imageUrl, // Regenerate mockup
                                  };
                              })
                            : [];

                    return {
                        templateId,
                        backgroundColor,
                        layers,
                    };
                });

                if (templates != null) {
                    try {
                        (async function () {
                            const { data } = await livePreview({
                                body: { templates },
                            });

                            const success = data?.success || [];
                            const errors = data?.errors || [];
                            const error = errors.find(Boolean);
                            if (success?.length > 0 && error == null) {
                                handleUpdateTemplate(templateSelected, success);
                            }
                        })();
                    } catch (err) {}
                }
            }
        },
        [templateSelected, livePreview, handleUpdateTemplate]
    );

    // Handle action
    const handleRegenerate = useCallback(() => {
        const baseConfig = [];

        if (mockupActive != null) {
            const {
                originTemplateId,
                taskBaseConfigId,
                newLayers,
                template,
                curLayers,
            } = mockupActive || {};

            let mergedLayers = [];
            let isNewLayers = newLayers?.length > 0;
            if (isNewLayers) {
                mergedLayers = newLayers;
            } else {
                const realLayers = preview ? filesCtx : template?.layers;
                const layersDesign = (realLayers || [])
                    .filter((i) => i.type === MOCKUP_TYPE.Design)
                    .map((l) => {
                        const curL = (curLayers || []).find(
                            (i) => i.name === l.name
                        );

                        if (curL != null && curL.x != null) {
                            return {
                                ...l,
                                ...curL,
                            };
                        }

                        return l;
                    });
                mergedLayers = layersDesign;
            }

            const layerConfigs = [];
            for (let i = 0; i < mergedLayers.length; i++) {
                const newLayer = mergedLayers[i];
                const {
                    x,
                    y,
                    width,
                    height,
                    opacity,
                    rotation,
                    designPosition,
                } = newLayer || {};
                const positionId = designPosition?.id; //designPositionId; //

                const layer = {
                    x: preview ? (x * 100) / percent : x,
                    y: preview ? (y * 100) / percent : y,
                    width: preview ? (width * 100) / percent : width,
                    height: preview ? (height * 100) / percent : height,
                    // x: (x * 100) / percent,
                    opacity,
                    rotation,
                    positionId,
                };

                if (positionId == null) continue;
                layerConfigs.push(layer);
            }

            const baseConfigId = taskBaseConfigId;
            baseConfig.push({
                originTemplateId,
                layerConfigs,
                baseConfigId,
            });
        }

        const baseGroupId = templateActive?.id;
        const baseGroups = [{ baseGroupId, baseConfig }];

        if (baseGroupId != null && baseConfig?.length > 0) {
            toggleActive(false);
            const data = {
                type: IFRAME_MESSAGE_TYPE.Regenerate,
                baseGroups: baseGroups,
            };
            window?.parent?.postMessage(JSON.stringify(data), "*");

            const newState = {
                loading: true,
            };
            updateState(newState);
        }
    }, [
        mockupActive,
        templateActive,
        percent,
        updateState,
        preview,
        toggleActive,
        filesCtx,
    ]);

    // Ref
    useImperativeHandle(
        ref,
        () => ({
            onGenerate: handleRegenerate,
        }),
        [handleRegenerate]
    );

    // const isNewLayers = mockupActive?.newLayers?.length > 0;
    const btnMarkup = useMemo(() => {
        // const label = isEditMockup ? "Custom artwork" : "Regenerate mockup";
        // const icon = isEditMockup ? UploadMajor : ReplaceMajor;
        // const onAction = isNewLayers ? handleRegenerate : toggleActive;
        const onAction = preview ? handleRegenerate : toggleActive;

        // isEditMockup
        // ? toggleOpen
        // :

        return (
            <Wrapper isIFrame={isIFrame}>
                <Button
                    // children="Regenerate mockup"
                    icon={ReplaceMajor}
                    onClick={onAction}
                    // loading={loading}
                    primary
                    loading={preview ? loading : undefined}
                />
            </Wrapper>
        );
    }, [
        // isEditMockup,
        // toggleOpen,
        handleRegenerate,
        loading,
        isIFrame,
        // isNewLayers,
        toggleActive,
        preview,
    ]);

    const regenerateBtn = (
        <Popover
            active={active}
            onClose={toggleActive}
            sectioned
            activator={btnMarkup}
        >
            <TextContainer>
                <p>Are you sure to re-generate mockup?</p>
                <Button
                    children="Submit"
                    onClick={handleRegenerate}
                    loading={loading}
                    size="slim"
                />
            </TextContainer>
        </Popover>
    );

    const handleSelect = useCallback(() => {
        if (files.length > 0) {
            const [firstVal] = files;
            const imageUrl = firstVal
                ? firstVal.url || firstVal.thumbnailUrl
                : null;

            const newFilesCtx = (filesCtx || []).map((f) => {
                if (f.type === MOCKUP_TYPE.Design) {
                    return {
                        ...f,
                        imageUrl,
                    };
                }
                return f;
            });

            updateFiles(newFilesCtx);

            // if (imageUrl != null) {
            //     handleLivePreview(imageUrl);
            // }
        }
    }, [files, filesCtx, updateFiles]);

    return (
        <Fragment>
            <Stack distribution="trailing">
                {/* {!isNewLayers ? regenerateBtn : btnMarkup} */}
                {!preview ? regenerateBtn : btnMarkup}
            </Stack>
            <ModalMedia
                open={open}
                onClose={toggleOpen}
                onSelect={handleSelect}
                setFiles={setFiles}
                files={files}
            />
        </Fragment>
    );
});

const Wrapper = styled.div`
    .Polaris-Button--primary {
        ${({ isIFrame }) =>
            isIFrame &&
            `    background: linear-gradient(180deg,#6371c7,#5563c1);
        border-color: #3f4eae;
        box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgb(22 29 37 / 5%), 0 0 0 0 transparent;`}
    }
`;
