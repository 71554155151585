import { Login } from "../pages/LoginPage";
import { DashboardPage } from "../pages/DashboardPage";
import { HomePage } from "../pages/HomePage";
import { Design } from "../components/Design";
import { KonvaComp } from "../components/Konva";
import { GenerateMockup } from "../components/GenerateMockup";

export const routes = [
    {
        path: "/",
        title: "Home page",
        component: HomePage,
    },
    {
        path: "/dashboard",
        title: "Dashboard page",
        component: DashboardPage,
    },
    {
        path: "/login",
        title: "Login page",
        component: Login,
    },
    {
        path: "/design",
        title: "Design page",
        component: Design,
    },
    {
        path: "/generate-mockup",
        title: "Generate mockup page",
        component: GenerateMockup,
    },
    {
        path: "/konva",
        title: "Konav",
        component: KonvaComp,
    },
];
