import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Card } from "@shopify/polaris";
import { isEqual } from "lodash";

import { API_DOMAIN } from "../../config";
import { useFetch } from "../../hooks";

import { Skeleton } from "../Shared/Skeleton";
import { EmptyStateComp } from "../Shared/EmptyState";
import { Filter } from "./Filter";
import { TemplateItems } from "./TemplateItems";

const URL = `${API_DOMAIN}/template`;

export function MockupTemplate({
    includesTemplateIds,
    setTemplateChoices,
    templateChoices,
}) {
    // State
    const [filter, setFilter] = useState({
        limit: 20,
        page: 1,
        search: "",
    });
    const [error, setError] = useState(null);
    const [data, setData] = useState({
        nodes: [],
        total: 0,
    });

    // Query
    const [getTemplates, { loading }] = useFetch({ url: URL, method: "get" });

    useEffect(() => {
        (async function () {
            try {
                const { data, error } = await getTemplates({
                    params: {
                        ...filter,
                        limit: includesTemplateIds ? 1000 : filter.limit,
                    },
                });

                if (error) {
                    setError(error);
                } else if (data != null) {
                    let newNodes = data.nodes;
                    if (includesTemplateIds?.length > 0) {
                        newNodes = data.nodes.filter((node) =>
                            includesTemplateIds.includes(node.id)
                        );
                    }

                    setData({
                        ...data,
                        nodes: newNodes,
                        total: includesTemplateIds
                            ? newNodes.length
                            : data.total,
                    });
                }
            } catch (err) {
                setError(err);
            }
        })();
    }, [filter, getTemplates, includesTemplateIds]);

    // Handle actions
    const handleFilterChange = useCallback(({ search }) => {
        setFilter((prev) => {
            if (!isEqual(prev.search, search)) {
                return {
                    ...prev,
                    search,
                    page: 1,
                };
            }

            return prev;
        });
    }, []);

    const handlePagination = useCallback(({ paged, limit }) => {
        setFilter((prev) => {
            if (!isEqual(prev.limit, limit)) {
                return {
                    ...prev,
                    limit,
                    page: 1,
                };
            }
            return { ...prev, page: paged };
        });
    }, []);

    return (
        <Fragment>
            <Card>
                <Card.Section>
                    <Filter filter={filter} onChange={handleFilterChange} />
                </Card.Section>
                <Card.Section>
                    {loading ? (
                        <Skeleton />
                    ) : error ? (
                        <div>Error: {error?.toString()}</div>
                    ) : data?.nodes?.length > 0 ? (
                        <TemplateItems
                            data={data}
                            filter={filter}
                            handlePagination={handlePagination}
                            setData={setData}
                            setTemplateChoices={setTemplateChoices}
                            templateChoices={templateChoices}
                        />
                    ) : (
                        <EmptyStateComp />
                    )}
                </Card.Section>
            </Card>
        </Fragment>
    );
}
