import { MockupTemplatesPage } from "../pages/MockupTemplatesPage";
import { CreateMockupTemplatePage } from "../pages/CreateMockupTemplatePage";
import { EditMockupTemplatePage } from "../pages/EditMockupTemplatePage";
import { MockupJobsPage } from "../pages/MockupJobsPage";

export const routes = [
    {
        path: "/mockup-templates",
        title: "Mockup Templates page",
        component: MockupTemplatesPage,
    },
    {
        path: "/mockup-templates/create",
        title: "Create Mockup Template page",
        component: CreateMockupTemplatePage,
    },
    {
        path: "/mockup-templates/update/:id",
        title: "Update Mockup Template page",
        component: EditMockupTemplatePage,
    },
    {
        path: "/mockup-jobs",
        title: "Mockup Jobs page",
        component: MockupJobsPage,
    },
];
