import React, { useMemo } from "react";
import { Navigation } from "@shopify/polaris";

import * as NAVIGATION from "../../constants/navigation";
import { ROLES } from "../../constants";

const ignoredMenu = [];

export const Sidebar = ({ pathname }) => {
    const theMenu = useMemo(() => {
        return Object.keys(ROLES)
            .map((key) => {
                const keyUpper = key.toUpperCase();
                let navigator = NAVIGATION[keyUpper];

                navigator = navigator
                    .map((nav) => {
                        if (nav.subNavigationItems?.length > 0) {
                            nav.subNavigationItems =
                                nav.subNavigationItems.filter(
                                    (subNav) =>
                                        !ignoredMenu.includes(subNav.key)
                                );
                        }

                        if (!ignoredMenu.includes(nav.key)) {
                            return nav;
                        }

                        return null;
                    })
                    .filter(Boolean);

                return navigator;
            })
            .filter(Boolean)[0];
    }, []);

    return (
        <Navigation location={pathname}>
            <Navigation.Section items={theMenu || []} />
        </Navigation>
    );
};
