import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AppProvider } from "@shopify/polaris";
import "@shopify/polaris/build/esm/styles.css";
import enTranslations from "@shopify/polaris/locales/en.json";

import { theme } from "./constants/theme";
import { routes } from "./routes/index";

import { LinkComp } from "./components/LinkComp";
import { FrameComp } from "./layout";
import { RenderRouter } from "./renderRouter";
import { AppContextProvider } from "./context";
import { NotFoundPage } from "./pages/NotFoundPage";

function App() {
    return (
        <AppProvider
            i18n={enTranslations}
            theme={theme}
            linkComponent={LinkComp}
        >
            <BrowserRouter>
                <AppContextProvider>
                    <FrameComp>
                        <Routes>
                            {routes.map((route, index) => {
                                const Comp = RenderRouter(route);
                                return (
                                    <Route
                                        key={`route-${index}`}
                                        path={route.path}
                                        element={<Comp />}
                                    />
                                );
                            })}
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </FrameComp>
                </AppContextProvider>
            </BrowserRouter>
        </AppProvider>
    );
}

export default App;
