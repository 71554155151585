import logo from "../assets/images/mb-logo_dark.png";

export const theme = {
    colors: {
        topBar: {
            background: "#fff",
        },
    },
    logo: {
        width: 180,
        topBarSource: logo,
        url: "/",
    },
};
