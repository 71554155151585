import React from "react";
import { Page } from "@shopify/polaris";

import { Create } from "../components/MockupTemplate/Create";
import { useAppContext } from "../context";

const url = "/mockup-templates";
export function CreateMockupTemplatePage() {
    const { isIFrame } = useAppContext();

    const props = {
        title: "Create Mockup Template",
        breadcrumbs: [{ content: "All Mockup Templates", url }],
    };

    return (
        <Page fullWidth {...(isIFrame ? {} : props)}>
            <div style={isIFrame ? { paddingTop: "1.6rem" } : {}}>
                <Create url={url} isIFrame={isIFrame} />
            </div>
        </Page>
    );
}
