import React, { useCallback, useEffect } from "react";
import { IFRAME_MESSAGE_TYPE } from "../../constants";
import { GenerateMockupProvider, useGenerateMockup } from "./context";
import { Config } from "./components";

export function GenerateMockup() {
    return (
        <GenerateMockupProvider>
            <GenerateMockupInner />
        </GenerateMockupProvider>
    );
}

function GenerateMockupInner() {
    const { updateState, templateSelected } = useGenerateMockup();

    // Get data from iframe MB
    const handleReceiveMessage = useCallback(
        (event) => {
            const data = event?.data;
            if (data != null && typeof data === "string") {
                const value = JSON.parse(window.atob(data));
                const { type, message } = value || {};
                const isGenerateMockup = type === IFRAME_MESSAGE_TYPE.GenerateMockup;
                if (!isGenerateMockup) return;

                const templateSelected = message;
                if (templateSelected?.length > 0) {
                    const [templateActive] = templateSelected;

                    updateState({
                        templateSelected,
                        templateActive,
                        loading: true,
                    });
                }
            }
        },
        [updateState]
    );

    useEffect(() => {
        window.addEventListener("message", handleReceiveMessage);

        return () => {
            window.removeEventListener("message", handleReceiveMessage, false);
        };
    }, [handleReceiveMessage]);

    return <Config />;
}
