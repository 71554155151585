import React, {
    useCallback,
    useMemo,
    useRef,
    useEffect,
    useState,
} from "react";
import { Icon, TextStyle } from "@shopify/polaris";
import { CircleTickMajor } from "@shopify/polaris-icons";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import img from "../../assets/images/img-placeholder.png";
import { formatTime } from "../../utils";

import { Pagination } from "../Shared/Pagination";
import { Delete } from "./actions/Delete";
import { useDesignContext } from "../Design/context";
import { useAppContext } from "../../context";

export function TemplateItems({
    data,
    filter,
    handlePagination,
    setData,
    setTemplateChoices,
    templateChoices,
}) {
    // Context
    const { isIFrame } = useAppContext();

    // Variables
    const { limit, page: paged } = filter;
    const total = get(data, "total", 0);
    const aggregation = {
        paged,
        limit,
        total,
        onChange: handlePagination,
    };

    return (
        <Wrapper isIFrame={isIFrame}>
            <div className="items-wrap">
                {data?.nodes?.map((node, index) => (
                    <Item
                        key={`template-${index}`}
                        item={node}
                        setData={setData}
                        templateChoices={templateChoices}
                        setTemplateChoices={setTemplateChoices}
                    />
                ))}
            </div>
            <Pagination aggregation={aggregation} showTotal />
        </Wrapper>
    );
}

function Item({ item, setData, templateChoices, setTemplateChoices }) {
    // Props
    const id = item?.id;
    const navigate = useNavigate();

    // Context
    const context = useDesignContext();
    const { isChooseTemplate } = context || {};

    // State
    const [height, setHeight] = useState(0);
    const itemRef = useRef(null);

    // Markup
    const descMarkup = useMemo(() => {
        const createdAt = formatTime(item.created_at);
        function formatValue(arr, join) {
            return arr.filter(Boolean).join(join);
        }
        const dimensions = formatValue([item.width, item.height], "x");

        return formatValue([createdAt, dimensions], " - ");
    }, [item]);

    const src = useMemo(() => {
        let file = item?.file;
        let result = file?.thumbnail_url
            ? file?.thumbnail_url
            : file?.url
            ? file.url
            : img;

        return result;
    }, [item]);

    // Handle actions
    const handleEdit = useCallback(() => {
        if (isChooseTemplate && item != null) {
            setTemplateChoices?.((prev) => {
                const clone = [...prev];
                const id = item.id;
                if (clone.length === 1 && id === clone[0]) return clone;
                const index = clone.findIndex((i) => i === id);

                if (index > -1) {
                    clone.splice(index, 1);
                } else {
                    clone.push(id);
                }

                return [...clone];
            });
        } else if (id != null) {
            navigate(`/mockup-templates/update/${id}`);
        }
    }, [id, navigate, isChooseTemplate, item, setTemplateChoices]);

    const handleUpdateData = useCallback(() => {
        setData((prev) => {
            const nodes = prev.nodes;
            let total = prev.total;

            if (!nodes || nodes.length === 0) return prev;
            const clone = [...nodes];
            const index = clone.findIndex((node) => node.id === id);

            if (index !== -1) {
                clone.splice(index, 1);
                total -= 1;
            }

            return {
                ...prev,
                nodes: clone,
                total,
            };
        });
    }, [setData, id]);

    useEffect(() => {
        if (!itemRef.current) return;

        const rect = itemRef.current.getBoundingClientRect();
        if (rect?.width) {
            setHeight(Number(rect.width));
        }
    }, []);

    const selected =
        templateChoices?.length > 0
            ? templateChoices.some((i) => i === id)
            : false;

    return (
        <div className="item-wrap" onClick={handleEdit}>
            {selected && (
                <div className="overlay">
                    <Icon
                        source={CircleTickMajor}
                        accessibilityLabel="selected"
                        color="success"
                    />
                </div>
            )}
            <div className="img-wrap" ref={itemRef}>
                <img
                    src={src}
                    alt=""
                    style={height ? { height } : {}}
                    onDragStart={(e) => {
                        e.preventDefault();
                    }}
                />
            </div>
            <div className="item-info">
                <TextStyle variation="strong">{item.name}</TextStyle>
                <div className="desc">
                    <TextStyle variation="subdued">{descMarkup}</TextStyle>
                </div>
                <div className="action-wrap">
                    {!isChooseTemplate && (
                        <Delete handleUpdateData={handleUpdateData} id={id} />
                    )}
                </div>
            </div>
        </div>
    );
}

const Wrapper = styled.div`
    .item-wrap {
        position: relative;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 100;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(170, 221, 205, 0.5);
            border-radius: var(--p-border-radius-wide, 3px);

            ${({ isIFrame }) =>
                isIFrame && `background: rgb(172 179 225 / 50%);`}

            .Polaris-Icon {
                height: 3.5rem;
                width: 3.5rem;
            }

            .Polaris-Icon__Svg {
                ${({ isIFrame }) =>
                    isIFrame &&
                    `
                    fill: rgba(85, 99, 193, 1);
                    `}
            }
        }
        .item-info {
            margin-top: 1.5rem;
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
        }

        .action-wrap {
            display: flex;
            flex-direction: row-reverse;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: var(--p-border-radius-wide, 3px);
        }
    }

    .Polaris-Button--primary {
        ${({ isIFrame }) =>
            isIFrame &&
            `    background: linear-gradient(180deg,#6371c7,#5563c1);
        border-color: #3f4eae;
        box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgb(22 29 37 / 5%), 0 0 0 0 transparent;`}
    }
`;
