import React, { Fragment, useCallback, useRef, useEffect } from "react";
import { Button, Modal } from "@shopify/polaris";
import { DeleteMajor } from "@shopify/polaris-icons";

import { useFetch, useToggle } from "../../../hooks";
import { API_DOMAIN } from "../../../config";
import { useToastContext } from "../../Toast";

const URL_TEMPLATE = `${API_DOMAIN}/template/delete`;

export function Delete({ id, handleUpdateData }) {
    // State
    const [open, toggleOpen] = useToggle(false);

    // Context
    const { toggleToast, setNotify } = useToastContext();

    const deleteTimeout = useRef(null);

    // Mutation
    const [deleteItem, { loading }] = useFetch({
        url: URL_TEMPLATE,
        method: "delete",
    });

    // Handle action
    const handleToggle = useCallback(
        (e) => {
            e.stopPropagation();
            toggleOpen();
        },
        [toggleOpen]
    );

    const handleDelete = useCallback(
        (e) => {
            e.stopPropagation();
            if (id != null) {
                toggleToast?.(true);
                setNotify({ msg: null, err: false });
                toggleOpen();

                (async function () {
                    try {
                        const data = await deleteItem({ body: { ids: [id] } });
                        if (data?.error) {
                            setNotify({
                                msg: data.error?.toString(),
                                err: true,
                            });
                        } else if (data.data) {
                            setNotify({
                                msg: "Delete template successfully.",
                                err: false,
                            });

                            deleteTimeout.current &&
                                clearTimeout(deleteTimeout.current);
                            deleteTimeout.current = setTimeout(() => {
                                handleUpdateData();
                            }, 1000);
                        }
                    } catch (err) {
                        setNotify({ msg: err?.toString(), err: true });
                    }
                })();
            }
        },
        [id, toggleToast, setNotify, toggleOpen, deleteItem, handleUpdateData]
    );

    useEffect(() => () => clearTimeout(deleteTimeout.current), []);

    return (
        <Fragment>
            <Button
                destructive
                plain
                icon={DeleteMajor}
                onClick={handleToggle}
            />
            <Modal
                title="Delete mockup template"
                open={open}
                onClose={handleToggle}
                sectioned
                secondaryActions={[
                    { content: "Cancel", onAction: handleToggle },
                ]}
                primaryAction={{
                    content: "Delete",
                    onAction: handleDelete,
                    destructive: true,
                    loading: loading,
                }}
                children="Are you sure to delete to this mockup template?"
            />
        </Fragment>
    );
}
