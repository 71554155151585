import React from "react";
import { Stack } from "@shopify/polaris";
import styled from "styled-components";

export function Config() {
    return (
        <Wrapper>
            <div className="toolbar-wrap" />
            <div className="design-wrap">
                <div className="content-wrap">
                    <div className="content">
                        <Stack vertical spacing="loose" wrap={false}></Stack>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    column-gap: 2rem;

    .toolbar-wrap {
        flex: 1 1 15%;
        max-width: 30rem;
    }

    .design-wrap {
        flex: 1 1;
    }

    .content {
        &-wrap {
            transition: border-color 0.2s ease-in-out;
            border: 0.2rem solid var(--p-border-highlight);
            border-radius: var(--p-border-radius-wide, 3px);
            width: 100%;
            display: flex;
            flex-direction: row;
        }

        background-color: var(--p-surface);
        flex: 1 1;
        border-radius: inherit;
        padding: 2rem;
        width: 80%;
    }
`;
