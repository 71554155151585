import React, { useEffect, useMemo, useRef, useState } from "react";
import { Transformer as KonvaTransformer } from "react-konva";

export function Transformer({
    selectedLayers,
    onTransformEnd = () => {},
    onDragMove = () => {},
    haveTransformer = false,
}) {
    // State
    const trRef = useRef();
    const [recheckNodes, setRecheckNodes] = useState(false);
    const selectedNodes = useMemo(() => {
        if (trRef && trRef.current) {
            const stage = trRef.current.getStage();
            return stage.find(
                (n) => selectedLayers.includes(n.getAttr("id")) && n.visible()
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLayers, trRef, recheckNodes]);

    useEffect(() => {
        window.trRef = trRef;
        if (trRef && trRef.current && selectedNodes) {
            // trRef.current.getLayer().find(".overlay").destroy();
            trRef.current.nodes(selectedNodes);
            trRef.current.getLayer().batchDraw();
        }
    }, [selectedNodes, selectedLayers]);

    const enabledAnchors = [
        "top-left",
        "top-right",
        "top-center",
        "middle-left",
        "middle-right",
        "bottom-center",
        "bottom-left",
        "bottom-right",
    ];

    return (
        <KonvaTransformer
            onMouseEnter={() => setRecheckNodes(true)}
            onMouseLeave={() => setRecheckNodes(false)}
            onUpdate={() => setRecheckNodes(!recheckNodes)}
            ref={trRef}
            onTransformEnd={onTransformEnd}
            onDragMove={onDragMove}
            enabledAnchors={haveTransformer ? [] : enabledAnchors}
            // rotateEnabled={false}
            draggable={true}
            rotateAnchorOffset={50}
            rotateAnchorCursor="pointer"
            anchorCornerRadius={2}
            borderEnabled={!haveTransformer}
            shouldOverdrawWholeArea={true}
            centeredScaling
            boundBoxFunc={(oldBox, newBox) => {
                if (newBox.width < 1) {
                    return oldBox;
                }
                return newBox;
            }}
            keepRatio={true}
            // borderDash={[5, 5]}
            // borderStroke={'red'}
        />
    );
}
