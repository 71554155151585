import moment from "moment";
import { MOCKUP_TYPE, TEAM_ROLE } from "../constants";

export function match(value, lookup, ...args) {
    if (value in lookup) {
        const returnValue = lookup[value];
        return typeof returnValue === "function"
            ? returnValue(...args)
            : returnValue;
    }
    return undefined;
}

export const isEmail = (email) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );

export const elContains = (parent, child) =>
    parent !== child && parent.contains(child);

export const hasError = (errors) => {
    return !Object.values(errors).every((i) => i === null);
};

export const getFile = (item) => {
    if (item == null) return null;
    const { id, key, name, url, thumbnail_url, mime_type } = item;

    return {
        id,
        key,
        name,
        url,
        thumbnailUrl: thumbnail_url,
        mimeType: mime_type,
    };
};

export const ImageTypes = ["image/png", "image/jpeg", "image/gif"];

export function getUniqueBy(arr = [], key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
}

export const formatFileSize = (bytes, decimalPoint) => {
    if (bytes === 0) return "0 Bytes";
    let k = 1000,
        dm = decimalPoint || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

function rgbFromHueAndChroma(hue, chroma) {
    const huePrime = hue / 60;
    const hueDelta = 1 - Math.abs((huePrime % 2) - 1);
    const intermediateValue = chroma * hueDelta;

    let red = 0;
    let green = 0;
    let blue = 0;
    if (huePrime >= 0 && huePrime <= 1) {
        red = chroma;
        green = intermediateValue;
        blue = 0;
    }

    if (huePrime >= 1 && huePrime <= 2) {
        red = intermediateValue;
        green = chroma;
        blue = 0;
    }

    if (huePrime >= 2 && huePrime <= 3) {
        red = 0;
        green = chroma;
        blue = intermediateValue;
    }

    if (huePrime >= 3 && huePrime <= 4) {
        red = 0;
        green = intermediateValue;
        blue = chroma;
    }

    if (huePrime >= 4 && huePrime <= 5) {
        red = intermediateValue;
        green = 0;
        blue = chroma;
    }

    if (huePrime >= 5 && huePrime <= 6) {
        red = chroma;
        green = 0;
        blue = intermediateValue;
    }

    return { red, green, blue };
}

export function hsbToRgb(color) {
    const { hue, saturation, brightness, alpha = 1 } = color;
    const chroma = brightness * saturation;

    let { red, green, blue } = rgbFromHueAndChroma(hue, chroma);

    const chromaBrightnessDelta = brightness - chroma;
    red += chromaBrightnessDelta;
    green += chromaBrightnessDelta;
    blue += chromaBrightnessDelta;

    return {
        red: Math.round(red * 255),
        green: Math.round(green * 255),
        blue: Math.round(blue * 255),
        alpha,
    };
}

export function RGBToHex(r, g, b) {
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);

    if (r.length === 1) r = "0" + r;
    if (g.length === 1) g = "0" + g;
    if (b.length === 1) b = "0" + b;

    return "#" + r + g + b;
}

// Pagination
export const customPaginate = (
    totalItems,
    currentPage = 1,
    pageSize = 10,
    maxPages = 10
) => {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // ensure current page isn't out of range
    if (currentPage < 1) {
        currentPage = 1;
    } else if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    let startPage, endPage, hasPrevious, hasNext;
    if (totalPages <= maxPages) {
        // total pages less than max so show all pages
        startPage = 1;
        endPage = totalPages;
    } else {
        // total pages more than max so calculate start and end pages
        let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
        let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            // current page near the start
            startPage = 1;
            endPage = maxPages;
            if (maxPages < totalPages) {
                hasPrevious = false;
                hasNext = true;
            }
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            // current page near the end
            startPage = totalPages - maxPages + 1;
            endPage = totalPages;
            if (maxPages < totalPages) {
                hasPrevious = true;
                hasNext = false;
            }
        } else {
            // current page somewhere in the middle
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
            if (maxPages < totalPages) {
                hasPrevious = true;
                hasNext = true;
            }
        }
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
        (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
        totalItems: totalItems,
        currentPage: currentPage,
        pageSize: pageSize,
        totalPages: totalPages,
        startPage: startPage,
        endPage: endPage,
        startIndex: startIndex,
        endIndex: endIndex,
        pages: pages,
        hasPrevious,
        hasNext,
    };
};

export const formatTime = (time, pattern = "MMMM DD, YYYY") => {
    if (time == null) return null;
    return moment(time).format(pattern);
};

export function placeCaretAtEnd(el) {
    el.focus();
    if (
        typeof window.getSelection != "undefined" &&
        typeof document.createRange != "undefined"
    ) {
        var range = document.createRange();
        range.selectNodeContents(el);
        range.collapse(false);
        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    } else if (typeof document.body.createTextRange != "undefined") {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
    }
}

export function checkIFrame() {
    return window !== window.top;
}

export const getURLParameters = (url) => {
    const newUrl = url.match(/([^?=&]+)(=([^&]*))/g);

    return (newUrl || []).reduce((a, v) => {
        const index = v.indexOf("=");
        a[v.slice(0, index)] = v.slice(index + 1);

        return a;
    }, {});
};

export function isTypeDesign(type) {
    if (!type) return false;
    return type === MOCKUP_TYPE.Design;
}

export function checkRole(user) {
    const { teamUser } = user || {};
    const { role } = teamUser || {};

    function cb(roleMatch) {
        return roleMatch.includes(role);
    }

    return {
        isDesigner: cb([TEAM_ROLE.Designer]),
    };
}
