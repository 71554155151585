import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useReducer
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { checkIFrame, getURLParameters } from "../utils";
import { ActionTypes } from "./actions";
import { stateReducer } from "./reducers";


const AppContext = createContext(null);
AppContext.displayName = "AppContext";

const token = localStorage.getItem("token");
const user = localStorage.getItem("user");

// initial values
const initialValues = {
    loading: false,
    user: user ? JSON.parse(user) : null,
    token: token,
    file: null,
    isIFrame: false,
    designPositions: [],
    templateSelected: [],
};

function AppContextProvider({ children }) {
    const [state, dispatch] = useReducer(stateReducer, initialValues);

    const location = useLocation();
    const navigate = useNavigate();

    // Handle action
    const addUserToLocalStorage = useCallback(({ user, token }) => {
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("token", token);
    }, []);

    const removeUserFromLocalStorage = useCallback(() => {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
    }, []);

    const login = useCallback(
        (res) => {
            const user = res.user;
            const token = res.id;
            dispatch({ type: ActionTypes.Login, user, token });
            addUserToLocalStorage({ user, token });
        },
        [addUserToLocalStorage]
    );

    const logout = useCallback(() => {
        dispatch({ type: ActionTypes.Logout });
        removeUserFromLocalStorage();
    }, [removeUserFromLocalStorage]);

    const selectFile = useCallback(
        (file) => {
            dispatch({ type: ActionTypes.ChooseFile, file });
        },
        [dispatch]
    );

    // Get data
    useEffect(() => {
        // `dps` => design position
        let { user, dps, pathname } = getURLParameters(location.search);

        const windowParent = window.window;
        if (user != null) {
            user = JSON.parse(windowParent.atob(user));
            login(user);

            if (pathname) {
                navigate(`/${pathname}`);
            }
        }

        if (dps != null) {
            dps = JSON.parse(windowParent.atob(dps));
            dispatch({ type: ActionTypes.UpdateDesignPosition, payload: dps });
        }
    }, [location.search, login, navigate]);

    useEffect(() => {
        if (checkIFrame()) {
            dispatch({ type: ActionTypes.CheckIFrame, payload: true });
        }

        return () => {
            if (state.isIFrame) {
                logout();
                dispatch({ type: ActionTypes.CheckIFrame, payload: false });
            }
        };
    }, [state.isIFrame, logout]);

    return (
        <AppContext.Provider
            value={{
                ...state,
                dispatch,
                login,
                logout,
                selectFile,
            }}
        >
            {children}
        </AppContext.Provider>
    );
}

function useAppContext() {
    return useContext(AppContext);
}

export { AppContextProvider, useAppContext, ActionTypes };
