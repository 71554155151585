import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export function NotFoundPage() {
    return (
        <Wrapper>
            <div className="notfound">
                <div className="notfound-404">
                    <h1>Oops!</h1>
                </div>
                <h2>404 - Page not found</h2>
                <Link to="/dashboard" className="Polaris-Link">
                    Go to Dashboard
                </Link>
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    position: relative;
    height: calc(100vh - 100px);

    .notfound {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: 41rem;
        width: 100%;
        text-align: center;

        .notfound-404 {
            height: 13rem;
            position: relative;
            z-index: -1;

            h1 {
                font-size: 10.5rem;
                margin: 0px;
                font-weight: 900;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                background-size: cover;
                background-position: center;
            }
        }

        h2 {
            font-size: 2.4em;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 2.5rem;
        }
    }
`;
