import React, {
    Fragment,
    useCallback,
    useEffect,
    useState,
    useRef,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Loading } from "@shopify/polaris";

import { API_DOMAIN } from "../../config";
import { useFetch } from "../../hooks";
import { Skeleton } from "../Shared/Skeleton";
import { Form } from "./Form";
import { useToastContext } from "../Toast";

const URL_UPDATE = `${API_DOMAIN}/template/update`;

export function Edit({ url, isIFrame }) {
    // Context
    const { toggleToast, setNotify } = useToastContext();
    const navigate = useNavigate();
    const timeRef = useRef(null);

    const { id } = useParams();
    const URL = `${API_DOMAIN}/template/${id}`;

    // State
    const [value, setValue] = useState(null);
    const [error, setError] = useState(null);
    const [postMessage, setPostMessage] = useState({
        loading: null,
        msg: null,
    });

    // Query
    const [getTemplate, { loading }] = useFetch({ url: URL, method: "get" });

    // Mutation
    const [updateMockup, { loading: loadingU }] = useFetch({
        url: URL_UPDATE,
        method: "put",
    });

    useEffect(() => {
        (async function () {
            try {
                const { data, error } = await getTemplate({});

                if (error != null) {
                    setError(error);
                } else if (data != null) {
                    setValue(data);
                }
            } catch (err) {
                setError(err);
            }
        })();
    }, [id, getTemplate]);

    // Handle actions
    const handleSubmit = useCallback(
        async (input) => {
            if (id != null) {
                toggleToast?.(true);
                setNotify?.({ msg: null, err: false })

                try {
                    setPostMessage((prev) => ({
                        ...prev,
                        loading: true,
                        msg: "",
                    }));
                    const { data, error } = await updateMockup({
                        body: { id, ...input },
                    });
                    if (data != null && !error) {
                        setNotify({
                            msg: "Update template successfully.",
                            err: false,
                        });

                        if (isIFrame) {
                            setPostMessage((prev) => ({
                                ...prev,
                                loading: false,
                                msg: "Completed",
                            }));
                            return;
                        }
                        timeRef.current = setTimeout(() => {
                            navigate(url);
                        }, 1000);
                    } else {
                        setNotify({
                            msg: error?.toString(),
                            err: true,
                        });
                        setPostMessage((prev) => ({
                            ...prev,
                            loading: false,
                            msg: "",
                        }));
                    }
                } catch (err) {
                    setNotify({ msg: err.toString(), err: true });
                }
            }
        },
        [updateMockup, setNotify, toggleToast, id, navigate, url, isIFrame]
    );

    useEffect(() => () => clearTimeout(timeRef.current), []);
    useEffect(() => {
        window?.parent?.postMessage(JSON.stringify(postMessage), "*");
    }, [postMessage]);

    // Markup
    const loadingMarkup = loading && <Loading />;

    return (
        <Fragment>
            {loadingMarkup}
            {loading ? (
                <Skeleton />
            ) : error ? (
                <div>Error: {error?.toString()}</div>
            ) : (
                <Form
                    value={value}
                    onSubmit={handleSubmit}
                    url={url}
                    loading={loadingU}
                    isIFrame={isIFrame}
                />
            )}
        </Fragment>
    );
}
