export const Production = true;
export const LocalApi = "http://localhost:8080";
export const LiveApi = "https://api-2dmockup.onemercegroup.com";
export const LocalApp = "http://localhost:3000";
export const LiveApp = "https://2dmockup.onemercegroup.com";
export const MbApi = Production
    ? "https://api.merchbridge.com"
    : "https://api-dev.merchbridge.com";
export const API_DOMAIN = Production
    ? "https://api-2dmockup.onemercegroup.com"
    : "http://localhost:8080";
