import React, { useCallback, useState } from "react";
import { Button, ButtonGroup, Popover, ActionList } from "@shopify/polaris";
import styled from "styled-components";

import { customPaginate } from "../../utils";

const Container = styled.div`
    padding: 1.6rem 0.3rem 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom-left-radius: var(--p-border-radius-wide, 3px);
    border-bottom-right-radius: var(--p-border-radius-wide, 3px);

    .control-pagination {
        display: flex;
        flex-direction: row;
        column-gap: 1rem;
    }

    .info-pagination {
        width: 40%;
        text-align: right;
    }
`;
const MAX_PAGES = 5;
const LIMITS = [10, 20, 30, 50, 100];

export function Pagination({
    aggregation: { paged, limit, onChange, total },
    showTotal,
}) {
    // Prop
    const totalPage = Math.ceil(total / limit);

    // State
    const [active, setActive] = useState(false);

    // Handle actions
    const handleChangePage = useCallback(
        (paged) => {
            if (onChange) {
                onChange({ paged, limit });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [limit]
    );
    const toggleActive = useCallback(() => setActive((prev) => !prev), []);

    // Variables
    const result = customPaginate(total, paged, limit, MAX_PAGES);
    let { pages } = result;

    if (!LIMITS.includes(limit)) {
        LIMITS.push(limit);
    }
    LIMITS.sort((a, b) => a - b);

    const options = LIMITS.map((item) => ({
        content: `${item} / page`,
        active: item === limit,
        onAction: () => {
            if (onChange) {
                onChange({ paged, limit: item });
            }
        },
    }));

    limit = typeof limit === "string" ? Number(limit) : limit;
    paged = typeof paged === "string" ? Number(paged) : paged;
    let start = (paged - 1) * limit + 1;
    let end = paged * limit;
    if (total < end) {
        end = total;
    }

    // Markup
    const labelMarkup = (
        <div className="custom-pagination">
            <ButtonGroup>
                {pages && pages.length >= MAX_PAGES && (
                    <Button
                        onClick={() => handleChangePage(1)}
                        children="<<"
                        disabled={paged === 1}
                    />
                )}
                <Button
                    onClick={() => handleChangePage(paged - 1)}
                    children="<"
                    disabled={paged === 1}
                />
                {pages &&
                    pages.map((p, index) => (
                        <Button
                            primary={paged === p}
                            children={p}
                            key={`button-${index}`}
                            onClick={() => handleChangePage(p)}
                        />
                    ))}
                <Button
                    onClick={() => handleChangePage(paged + 1)}
                    children=">"
                    disabled={paged === totalPage}
                />
                {paged && paged.length >= MAX_PAGES && (
                    <Button
                        onClick={() => handleChangePage(totalPage)}
                        children=">>"
                        disabled={paged === totalPage}
                    />
                )}
            </ButtonGroup>
        </div>
    );
    const activatorMarkup = (
        <Button onClick={toggleActive} disclosure>
            {limit} / page
        </Button>
    );
    return (
        <Container>
            <div className="control-pagination">
                {labelMarkup}
                {showTotal && (
                    <Popover
                        active={active}
                        onClose={toggleActive}
                        activator={activatorMarkup}
                    >
                        <ActionList items={options} />
                    </Popover>
                )}
            </div>
            {showTotal && (
                <>
                    <span className="info-pagination">
                        <div className="info_wrap">
                            <strong>{start}</strong> to <strong>{end}</strong>{" "}
                            of <strong>{total}</strong>{" "}
                            {total > 1 ? "items" : "item"}
                        </div>
                    </span>
                </>
            )}
        </Container>
    );
}
