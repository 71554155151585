import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { Button, ButtonGroup, Collapsible } from "@shopify/polaris";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import styled from "styled-components";

import { useAppContext } from "../../context";
import { useMockupTemplateCtx } from "./context";
import { useToggle } from "../../hooks";
import { ModalMedia } from "../Media";
import { MOCKUP_TYPE } from "../../constants";
import { Layer } from "./Layer";
import { LayerSetting } from "./LayerSetting";
import { PrintArea } from "./PrintArea";

export function Layers() {
    // State
    const [open, toggleOpen] = useToggle(false);
    const [values, setValues] = useState([]);

    const fileRef = useRef(false);

    // Context
    const {
        files,
        fileActive,
        toggleFileActive,
        addItem,
        removeItem,
        updateState,
        updateFiles,
        relativeWidth,
        relativeHeight,
        percent,
    } = useMockupTemplateCtx();
    const { file, selectFile } = useAppContext();

    // Get data context
    useEffect(() => {
        if (file != null && fileRef.current === false) {
            const img = new Image();
            img.src = file?.url;
            img.onload = () => {
                const width = img?.width || 0;
                const height = img?.height || 0;

                updateState({ width, height });
                addItem(
                    { ...file, type: MOCKUP_TYPE.Background },
                    width,
                    height
                );
            };
            selectFile(null);
            fileRef.current = true;
        }
    }, [file, addItem, updateState, selectFile]);

    // Handle actions
    const handleRemove = useCallback((id) => removeItem(id), [removeItem]);
    const handleExpanded = useCallback(
        (id) => {
            const newFiles = files?.map((file) => {
                if (file.id === id) {
                    return {
                        ...file,
                        x: 0,
                        y: 0,
                        width: relativeWidth,
                        height: relativeHeight,
                    };
                }
                return file;
            });

            updateFiles(newFiles);
        },
        [updateFiles, files, relativeWidth, relativeHeight]
    );

    const handleSelect = useCallback(() => {
        const [file] = values;
        if (file != null) {
            addItem({ ...file, type: MOCKUP_TYPE.Background });
        }
    }, [values, addItem]);

    const handleTitleUpdate = useCallback(
        (e, id) => {
            const content = e?.target.textContent ?? "";
            const newFiles = files?.map((file) => {
                if (file.id === id) {
                    return {
                        ...file,
                        elName: content || file.elName,
                    };
                }
                return file;
            });

            updateFiles(newFiles);
        },
        [updateFiles, files]
    );

    const handleActiveItem = useCallback(
        (id) => {
            toggleFileActive(id);
        },
        [toggleFileActive]
    );

    const handleSortEnd = useCallback(
        ({ oldIndex, newIndex }) => {
            const newLayers = arrayMove(files, oldIndex, newIndex);
            updateFiles(newLayers);
        },
        [files, updateFiles]
    );

    const showLayerSetting = useMemo(() => {
        return Object.entries(fileActive).find(([_k, value]) => Boolean(value));
    }, [fileActive]);

    return (
        <Wrapper>
            <SortableList
                layers={files}
                lockAxis="y"
                useDragHandle
                handleRemove={handleRemove}
                handleExpanded={handleExpanded}
                handleTitleUpdate={handleTitleUpdate}
                handleActiveItem={handleActiveItem}
                fileActive={fileActive}
                onSortEnd={handleSortEnd}
                helperClass="draging"
                helperContainer={
                    document.getElementsByClassName("layer-list")[0]
                }
            />
            <div className="btn-layer-wrap">
                <ButtonGroup fullWidth>
                    <Button children="Add Image" onClick={toggleOpen} />
                    <PrintArea addItem={addItem} />
                </ButtonGroup>
            </div>
            <div>
                <Collapsible
                    id="layer-setting"
                    open={!!showLayerSetting}
                    expandOnPrint
                    transition={{
                        duration: "500ms",
                        timingFunction: "ease-in-out",
                    }}
                >
                    <div className="layer-setting">
                        <LayerSetting
                            id={showLayerSetting?.[0]}
                            files={files}
                            updateFiles={updateFiles}
                            percent={percent}
                        />
                    </div>
                </Collapsible>
            </div>
            <ModalMedia
                open={open}
                onClose={toggleOpen}
                files={values}
                setFiles={setValues}
                onSelect={handleSelect}
            />
        </Wrapper>
    );
}

const SortableItem = SortableElement(
    ({
        layer,
        fileActive,
        handleRemove,
        handleExpanded,
        handleTitleUpdate,
        handleActiveItem,
    }) => {
        return (
            <li style={{ listStyle: "none" }} key={layer.id}>
                <Layer
                    item={layer}
                    onRemove={() => handleRemove(layer.id)}
                    onExpanded={() => handleExpanded(layer.id)}
                    onChange={(e) => handleTitleUpdate(e, layer.id)}
                    onActiveItem={() => handleActiveItem(layer.id)}
                    isActive={fileActive[layer.id]}
                />
            </li>
        );
    }
);

const SortableList = SortableContainer(({ layers, ...props }) => (
    <ul className="layer-list">
        {layers.map((_, index) => {
            const layer = layers[index];

            return (
                <SortableItem
                    key={layer.id}
                    index={index}
                    layer={layer}
                    layers={layers}
                    {...props}
                />
            );
        })}
    </ul>
));

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;

    .layer-list {
        padding: 0;
    }

    .Polaris-Labelled__LabelWrapper {
        margin-bottom: 0;
    }

    .item,
    .group-control {
        display: flex;
        flex-direction: row;
        justify-content: start;
        column-gap: 0.5rem;
        align-items: center;
        padding: 0.25rem;
        border-radius: 3px;

        .Polaris-Icon {
            margin: 0;
            height: 1.785rem;
            width: 1.785rem;
        }
    }

    .action-layer {
        display: flex;
        flex-direction: row;
        column-gap: 0.5rem;
    }

    .item.active {
        background-color: var(--p-background-selected);
        color: var(--p-text-primary);

        svg {
            fill: var(--p-action-primary);
        }
    }

    .group-control {
        flex: 1 1;
        cursor: pointer;

        .name {
            word-break: break-all;
            font-size: 13px;
            flex: 1 1;
            cursor: pointer;
            transition: 0.2s all;

            &.focus-visible {
                padding-left: 0.5rem;
            }
        }
    }

    .btn-layer-wrap {
        margin-top: 0.785rem;
    }

    .layer-setting {
        margin-top: 1rem;
        padding-top: 1rem;
        border-top: 2px solid var(--p-divider);

        .Polaris-Icon {
            height: 1.5rem;
            width: 1.5rem;
        }
    }
`;
