import { Page } from "@shopify/polaris";
import React, {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { useToggle } from "../hooks";
import { ModalMedia } from "../components/Media";
import { useAppContext } from "../context";
import { MockupTemplate } from "../components/MockupTemplate";

export function MockupTemplatesPage() {
    // state
    const [open, toggleOpen] = useToggle(false);

    return (
        <Fragment>
            <Page
                title="All Mockup Template"
                fullWidth
                primaryAction={{
                    content: "Create New Mockup Template",
                    onAction: toggleOpen,
                }}
            >
                <MockupTemplate includesTemplateIds={[]} />
            </Page>
            <ChooseImage open={open} onClose={toggleOpen} />
        </Fragment>
    );
}

function ChooseImage({ open, onClose }) {
    // State
    const [files, setFiles] = useState([]);
    const timeoutRef = useRef(null);

    // Context
    const { selectFile } = useAppContext();
    const navigate = useNavigate();

    const handleSelect = useCallback(() => {
        const [file] = files?.filter(Boolean);

        selectFile(file);
        timeoutRef.current = setTimeout(() => {
            navigate("/mockup-templates/create");
        }, 100);
    }, [files, selectFile, navigate]);

    // Did mount
    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    return (
        <ModalMedia
            open={open}
            onClose={onClose}
            files={files}
            setFiles={setFiles}
            onSelect={handleSelect}
        />
    );
}
